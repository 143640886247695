import React, { useRef, useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Loader from './Loader';
import Message from './Message';
import { useGetFontProductsQuery, useGetProductsNavQuery } from '../slices/productsApiSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@awesome.me/kit-367a27fd96/icons/classic/light';
import { faHouse, faChevronLeft, faChevronRight, faGalleryThumbnails } from '@awesome.me/kit-367a27fd96/icons/classic/light';
// import * as Icons from '@awesome.me/kit-367a27fd96/icons/duotone/solid';
// import { faHouse, faChevronLeft, faChevronRight, faGalleryThumbnails } from '@awesome.me/kit-367a27fd96/icons/duotone/solid';
const HeaderNav = ({ onProductSelect, clearHomeScreen }) => {
    const { data: fontProducts, isLoading: isFontLoading, error: fontError } = useGetFontProductsQuery();
    const { data: allProducts, isLoading: isAllLoading, error: allError } = useGetProductsNavQuery({ keyword: '', pageNumber: 1 });
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startScrollLeft, setStartScrollLeft] = useState(0);

    const scrollRef = useRef(null);

    const startDragging = (e) => {
        setIsDragging(true);
        const clientX = e.type === 'touchstart' ? e.touches[0].clientX : e.clientX;
        setStartX(clientX);
        setStartScrollLeft(scrollRef.current.scrollLeft);
    };

    const stopDragging = () => {
        setIsDragging(false);
    };

    const onDragging = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const clientX = e.type === 'mousemove' ? e.clientX : e.touches[0].clientX;
        const walk = (clientX - startX) * 0.8;
        scrollRef.current.scrollLeft = startScrollLeft - walk;
    };

    useEffect(() => {
        const element = scrollRef.current;
        if (!element) return;

        element.addEventListener('mousemove', onDragging);
        element.addEventListener('touchmove', onDragging, { passive: false });

        return () => {
            element.removeEventListener('mousemove', onDragging);
            element.removeEventListener('touchmove', onDragging);
        };
    }, [isDragging, startX, startScrollLeft]);

    const scrollLeft = () => scrollRef.current.scrollBy({ left: -100, behavior: 'smooth' });
    const scrollRight = () => scrollRef.current.scrollBy({ left: 100, behavior: 'smooth' });

    // maps the font items on the navbar
    const renderProducts = (products, onProductSelect) => {
        return products.map((product, index) => {
            const iconName = `${product.font.charAt(0) + product.font.slice(1)}`;
            const IconComponent = Icons[iconName] ? Icons[iconName] : Icons.faQuestionCircle;
            return (
                <LinkContainer key={index} to={`/search/${product.productType}`} onClick={() => onProductSelect(product.productTypeDescription)}>
                    <Nav.Link className="icon-link-container" draggable="false">
                        <div className="icon-wrapper" draggable="false">
                            {IconComponent && <FontAwesomeIcon icon={IconComponent} color="black" className="icon" draggable="false" />}
                            <span className="icon-text" draggable="false">{product.productType}</span>
                        </div>
                    </Nav.Link>
                </LinkContainer>
            );
        });
    };

    if (isFontLoading || isAllLoading) return <Loader />;
    if (fontError || allError) return <Message variant="danger">{fontError || allError}</Message>;

    return (
        <header>
            <Navbar
                className="horizontal-scroll-nav headerNavSpacing"
                style={{ cursor: isDragging ? 'grabbing' : 'grab', backgroundColor: "#ffffff", height: '100px'}}
            >
                <Container fluid>
                    <Navbar.Toggle className="custom-toggle" aria-controls="basic-navbar-nav" />
                    {/*styles and size the scroll bar icons*/}
                    <div style={{ display: 'flex', width: '100%'}}>
                        <button className="nav-arrow left" onClick={scrollLeft}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <div ref={scrollRef} style={{ overflowX: 'auto', flexGrow: 1 }} onMouseDown={startDragging} onMouseLeave={stopDragging} onMouseUp={stopDragging} onTouchStart={startDragging} onTouchEnd={stopDragging}>
                            {/*formats the scroll icons*/}
                            <nav className="navbar-nav">
                                <LinkContainer to='/' onClick={() => clearHomeScreen()}>
                                    <Nav.Link className="icon-link-container" draggable="false">
                                        <div className="icon-wrapper" draggable="false">
                                            <FontAwesomeIcon icon={faHouse} size="2x" className="icon" draggable="false"/>
                                            <span className="icon-text" draggable="false">Home</span>
                                        </div>
                                    </Nav.Link>
                                </LinkContainer>
                                <LinkContainer to={'/search/'} >
                                    <Nav.Link className="icon-link-container" draggable="false">
                                        <div className="icon-wrapper" draggable="false">
                                            <FontAwesomeIcon icon={faGalleryThumbnails} size="2x" className="icon" draggable="false"/>
                                            <span className="icon-text" draggable="false">All Items</span>
                                        </div>
                                    </Nav.Link>
                                </LinkContainer>
                                {renderProducts(fontProducts, onProductSelect)}
                            </nav>
                        </div>
                        <button className="nav-arrow right" onClick={scrollRight}>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                </Container>
            </Navbar>
        </header>
    );
};

export default HeaderNav;
