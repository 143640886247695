import React, { useState, useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FaEdit, FaTimes, FaPlus, FaFileCsv } from 'react-icons/fa';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import { useCreateProductMutation, useGetProductsQuery, useDeleteProductMutation } from '../../slices/productsApiSlice';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import Paginate from '../../components/Paginate';
import { CSVLink } from 'react-csv';

const ProductListScreen = () => {
    const { pageNumber } = useParams();
    const navigate = useNavigate();
    const { data, isLoading, error, refetch } = useGetProductsQuery({ pageNumber });
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortedProducts, setSortedProducts] = useState([]);
    const [filterQuery, setFilterQuery] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState(filterQuery);

    useEffect(() => {
        if (data?.products) {
            setSortedProducts(data.products);
        }
    }, [data]);

    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedQuery(filterQuery);
        }, 300); // Delay in ms to debounce filter input
        return () => clearTimeout(timerId);
    }, [filterQuery]);

    const handleSort = (columnName) => {
        const direction = sortDirection === 'asc' ? 'desc' : 'asc';
        setSortDirection(direction);
        const sorted = [...sortedProducts].sort((a, b) => {
            if (a[columnName] < b[columnName]) return direction === 'asc' ? -1 : 1;
            if (a[columnName] > b[columnName]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
        setSortedProducts(sorted);
    };

    const filteredProducts = sortedProducts.filter(product =>
        product.name.toLowerCase().includes(debouncedQuery.toLowerCase())
    );

    const [createProduct, { isLoading: loadingCreate }] = useCreateProductMutation();
    const [deleteProduct, { isLoading: loadingDelete }] = useDeleteProductMutation();

    const deleteHandler = async (id) => {
        if (window.confirm('Are you sure you want to delete this product?')) {
            try {
                await deleteProduct(id);
                toast.success('Product deleted successfully');
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || 'An unexpected error occurred');
            }
        }
    };

    const createProductHandler = async () => {
        if (window.confirm('Are you sure you want to create a new product?')) {
            try {
                const defaultNewProduct = {
                    name: 'Sample name',
                    price: 0,
                    brand: 'Sample brand',
                    category: 'Sample category',
                    countInStock: 0,
                    description: 'Sample description',
                    image: '/images/sample.jpg',
                    fontCategory: 'Sample font category',
                    font: 'Sample font',
                    genre: 'Sample genre',
                    productType: 'Sample product type',
                    character: 'Sample character',
                    fontOrder: 0,
                    fontImage: false,
                    colour: ['Sample colour'],
                    searchingStyle: 'Sample searching style',
                    rating: 0,
                    numReviews: 0,
                    carouselImage: false,
                    imageArray: ['/images/sample.jpg'],
                    sizeArray: [{ size: 'M', tshirtInStock: 0 }],
                };
                const newProduct = await createProduct({ ...defaultNewProduct }).unwrap();
                toast.success('Product created successfully');
                navigate(`/admin/product/${newProduct._id}/edit`);
            } catch (err) {
                toast.error(err?.data?.message || 'An unexpected error occurred');
            }
        }
    };

    const csvData = filteredProducts.map(product => ({
        ID: product._id,
        Name: product.name,
        Price: product.price,
        Category: product.category,
        Brand: product.brand,
        Genre: product.genre,
        'Product Type': product.productType,
        Character: product.character,
        Font: product.font,
        'Font Image': product.fontImage ? 'Yes' : 'No',
        'Font Order': product.fontOrder,
        Description: product.description,
        Colours: product.colour.join(', '),
        'Product Type Description': product.productTypeDescription,
        'Searching Style': product.searchingStyle,
        'Count In Stock': product.countInStock,
        Rating: product.rating,
        'Number of Reviews': product.numReviews,
        'Carousel Image': product.carouselImage ? 'Yes' : 'No',
        'Image Array': product.imageArray.join(', '),
        'Size Array': product.sizeArray.map(size => `${size.size}: ${size.tshirtInStock}`).join(', '),
    }));

    return (
        <>
            <Row className='align-items-center'>
                <Col>
                    <h1>Products</h1>
                </Col>
                <Col className='text-right'>
                    <Button className='my-3' onClick={createProductHandler}>
                        <FaPlus /> Create Product
                    </Button>
                    <CSVLink data={csvData} filename="products.csv" className="btn btn-primary mx-2">
                        <FaFileCsv /> Export to CSV
                    </CSVLink>
                </Col>
            </Row>
            <Row>
                <Col>
                    <input
                        type='text'
                        className='form-control mb-3'
                        placeholder='Filter products...'
                        value={filterQuery}
                        onChange={(e) => setFilterQuery(e.target.value)}
                    />
                </Col>
            </Row>
            {loadingCreate && <Loader />}
            {loadingDelete && <Loader />}
            {isLoading ? (
                <Loader />
            ) : error ? (
                <Message variant='danger'>{error.data.message}</Message>
            ) : (
                <>
                    <Table striped bordered hover responsive className='table-sm'>
                        <thead>
                        <tr>
                            <th>EDIT</th>
                            <th>ID</th>
                            <th onClick={() => handleSort('name')}>Name {sortDirection === 'asc' ? '↓' : '↑'}</th>
                            <th onClick={() => handleSort('price')}>Price {sortDirection === 'asc' ? '↓' : '↑'}</th>
                            <th onClick={() => handleSort('category')}>Category {sortDirection === 'asc' ? '↓' : '↑'}</th>
                            <th>Brand</th>
                            <th>Genre</th>
                            <th>Product Type</th>
                            <th onClick={() => handleSort('character')}>Character {sortDirection === 'asc' ? '↓' : '↑'}</th>
                            <th>Font</th>
                            <th>Font Image</th>
                            <th onClick={() => handleSort('fontOrder')}>Font Order {sortDirection === 'asc' ? '↓' : '↑'}</th>
                            <th>Description</th>
                            <th>Colours</th>
                            <th>Product Type Description</th>
                            <th>Searching Style</th>
                            <th>Count In Stock</th>
                            <th>Rating</th>
                            <th>Number of Reviews</th>
                            <th>Carousel Image</th>
                            <th>Image Array</th>
                            <th>Size Array</th>
                            <th>DELETE</th>
                        </tr>
                        </thead>
                        <tbody>
                        {filteredProducts.length > 0 ? (
                            filteredProducts.map((product) => (
                                <tr key={product._id}>
                                    <td>
                                        <LinkContainer to={`/admin/product/${product._id}/edit`}>
                                            <Button variant='light' className='btn-sm mx-2'>
                                                <FaEdit />
                                            </Button>
                                        </LinkContainer>
                                    </td>
                                    <td>{product._id}</td>
                                    <td>{product.name}</td>
                                    <td>{product.price}</td>
                                    <td>{product.category}</td>
                                    <td>{product.brand}</td>
                                    <td>{product.genre}</td>
                                    <td>{product.productType}</td>
                                    <td>{product.character}</td>
                                    <td>{product.font}</td>
                                    <td>{product.fontImage ? 'Yes' : 'No'}</td>
                                    <td>{product.fontOrder}</td>
                                    <td>{product.description}</td>
                                    <td>{product.colour.join(', ')}</td>
                                    <td>{product.productTypeDescription}</td>
                                    <td>{product.searchingStyle}</td>
                                    <td>{product.countInStock}</td>
                                    <td>{product.rating}</td>
                                    <td>{product.numReviews}</td>
                                    <td>{product.carouselImage ? 'Yes' : 'No'}</td>
                                    <td>
                                        {product.imageArray.map((image, index) => (
                                            <div key={index}>{image}</div>
                                        ))}
                                    </td>
                                    <td>
                                        {product.sizeArray.map((size, index) => (
                                            <div key={index}>
                                                {size.size}: {size.tshirtInStock}
                                            </div>
                                        ))}
                                    </td>
                                    <td>
                                        <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(product._id)}>
                                            <FaTimes />
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan='23'>No products found.</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                    <Paginate pages={data.pages} page={data.page} isAdmin={true} />
                </>
            )}
        </>
    );
};

export default ProductListScreen;