import React from 'react';
import {Helmet} from "react-helmet-async";

const Meta = ({title, description, keywords }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description}/>
            <meta name="keywords" content={keywords}/>
        </Helmet>
    );
}

Meta.defaultProps = {
    title: 'Welcome To RetroDayz',
    description: 'Welcome to RetroDayz – your ultimate destination for all things retro! Dive into nostalgia with our curated ' +
        'selection of Marvel, Capcom, DC, and gaming memorabilia. From classic arcade to vintage electronics, we have it. ' +
        'Explore our collection of T-shirts, gifts, toys, action figures, and collectibles. Shop now at ' +
        'RetroDayz.com and bring the past to life!',
    keywords: 'Marvel, capcom, DC, Retro, Gaming, Arcades, electronics, Stranger Things, ' +
              'Tshirts gifts for men, gifts for her, gifts for kids, gifts for him, toys, action figures, ' +
              'collectibles, retrodayz, retrodayz.com, retrodayz store, retrodayz shop, retrodayz merchandise, ' +
              'retrodayz merchandise store, retrodayz merchandise shop, retrodayz merchandise online, steam deck, nintendo switch, ' +
                'playstation, xbox, arcade1up, street fighter, zavvi, lights, lamp, lamps, mugs, mug, cup, plush, mario, sonic, arcade' +
                'retrodayz, retrodayz.com, retrodayz store, retrodayz shop, retrodayz merchandise, he-man, back to the future'
    ,

}

export default Meta;