import React, { useRef, useState } from 'react';
import capcom from '../assets/capcom.png';
import marvel from '../assets/marvel.webp';
import dc from '../assets/DC_COMICS.webp';
import sw from '../assets/STARWARS.webp';
import gb from '../assets/ghostbusters-logo.png';
import st from '../assets/stranger-things-logo.png';
import ni from '../assets/Mario_Series_Logo.png';
import xbox from '../assets/xboxonelogo.png';
import ps from '../assets/playstation.png';
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const IconModel = ({ src, width, height, alt }) => {
    const [isMousedOver, setIsMousedOver] = useState(false);

    const handleMouseHover = () => {
        setIsMousedOver(true);
    };

    const handleMouseOut = () => {
        setIsMousedOver(false);
    };

    return (
        <img
            src={src}
            width={width}
            height={height}
            style={{
                opacity: isMousedOver ? 1 : 0.5,
                padding: 0,
                transition: 'opacity 0.3s ease'
            }}
            className="img-fluid"
            onMouseOver={handleMouseHover}
            onMouseOut={handleMouseOut}
            alt={alt}
            draggable="false"
        />
    );
};

const ImageLayout = ({ onIconClick }) => {
    const navigate = useNavigate();
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const scrollRef = useRef(null);

    const handleIconClick = (keyword, updateDescription) => {
        if (isDragging) return;
        navigate(`/search/${keyword}`);
        onIconClick(updateDescription);
    };

    const startDragging = (e) => {
        setIsDragging(true);
        const pageX = e.pageX || e.touches[0].pageX;
        setStartX(pageX - scrollRef.current.offsetLeft);
        setScrollLeft(scrollRef.current.scrollLeft);
    };

    const onDragging = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const pageX = e.pageX || e.touches[0].pageX;
        const x = pageX - scrollRef.current.offsetLeft;
        const walk = (x - startX) * 0.8;
        scrollRef.current.scrollLeft = scrollLeft - walk;
    };

    const stopDragging = () => {
        setIsDragging(false);
    };

    const iconsData = [
        { src: marvel, alt: 'Marvel', alt2: 'In a world of Spiderman, Ironman, and the Avengers, we have some great Marvel gift ideas!!' },
        { src: ps, alt: 'Playstation', alt2: 'Dip into the world of gaming with our range of Playstation products!' },
        { src: ni, alt: 'Nintendo', alt2: 'Jump into the world of Mario, Zelda, and more with our range of Nintendo products!' },
        { src: dc, alt: 'DC Comics', alt2: 'Superman, Batman and Wonderwoman are just a few of the DC characters we have products for!' },
        { src: st, alt: 'Stranger Things', alt2: 'In the upside down world of Stranger Things, we have the best products for you!' },
        { src: gb, alt: 'Ghostbusters', alt2: 'If theres something strange in your neighbourhood, who you gonna call? Ghostbusters!' },
        { src: sw, alt: 'Star Wars', alt2: 'These are not the droids you are looking for, but we have the best Star Wars products for you!' },
        { src: xbox, alt: 'Xbox', alt2: 'Halo and Gears of War are just a few of the Xbox games that inspire our range of products!' },
        { src: capcom, alt: 'Capcom', alt2: 'Hadooken! Capcom has some of the best games and products for you!' },
    ];

    return (
        <Container fluid >
            <Row
                ref={scrollRef}
                className="horizontal-scroll"
                style={{
                    cursor: isDragging ? 'grabbing' : 'grab',
                }}
                onMouseDown={startDragging}
                onMouseMove={onDragging}
                onMouseLeave={stopDragging}
                onMouseUp={stopDragging}
                onTouchStart={startDragging}
                onTouchMove={onDragging}
                onTouchEnd={stopDragging}
            >
                {iconsData.map((icon, index) => (
                    <Col
                        key={index}
                        onClick={() => handleIconClick(icon.alt, icon.alt2)}
                        className="col"
                        style={{
                            width: 'auto',
                            padding: '0px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: '70px' // Add spacing between the icons
                        }}
                        sm={12} md={6} lg={4} xl={3}
                    >
                        <IconModel
                            src={icon.src}
                            width="100%"
                            height="auto"
                            alt={icon.alt}
                        />
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default ImageLayout;
