import React, { useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Carousel, Image, Card, Button } from 'react-bootstrap';
import Loader from "./Loader";
import Message from "./Message";
import { useGetProductDetailsQuery } from "../slices/productsApiSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ProductsItemsCarousel = () => {
    const { id: productId } = useParams(); // Get product ID from URL parameters
    const { data: product, isLoading, error } = useGetProductDetailsQuery(productId); // Fetch product details
    const [activeIndex, setActiveIndex] = useState(0); // State for active image index
    const thumbnailRef = useRef(null); // Ref for the thumbnail container
    const isDragging = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);

    // Function to handle selecting a thumbnail
    const handleSelect = (selectedIndex) => {
        setActiveIndex(selectedIndex);
    };

    // Function to handle going to the previous image
    const handlePrevThumbnail = () => {
        if (product?.imageArray) {
            const newIndex = activeIndex === 0 ? product.imageArray.length - 1 : activeIndex - 1;
            setActiveIndex(newIndex);
            thumbnailRef.current.scrollBy({ left: -110, behavior: 'smooth' });
        }
    };

    // Function to handle going to the next image
    const handleNextThumbnail = () => {
        if (product?.imageArray) {
            const newIndex = activeIndex === product.imageArray.length - 1 ? 0 : activeIndex + 1;
            setActiveIndex(newIndex);
            thumbnailRef.current.scrollBy({ left: 110, behavior: 'smooth' });
        }
    };

    // Function to enable dragging the carousel container
    const handleMouseDown = (e) => {
        isDragging.current = true;
        startX.current = e.pageX - thumbnailRef.current.offsetLeft;
        scrollLeft.current = thumbnailRef.current.scrollLeft;
    };

    // Function to handle mouse leave on the thumbnail container
    const handleMouseLeave = () => {
        isDragging.current = false;
    };

    // Function to handle mouse up on the thumbnail container
    const handleMouseUp = () => {
        isDragging.current = false;
    };

    // Function to handle mouse move on the thumbnail container
    const handleMouseMove = (e) => {
        if (!isDragging.current) return;
        e.preventDefault();
        const x = e.pageX - thumbnailRef.current.offsetLeft;
        const walk = (x - startX.current) * 2; // Scroll speed
        thumbnailRef.current.scrollLeft = scrollLeft.current - walk;
    };

    return isLoading ? (
        // Display loader while data is being fetched
        <Loader />
    ) : error ? (
        // Display error message if there's an error in fetching data
        <Message variant='danger'>{error}</Message>
    ) : (
        <Card className="my-3 p-3 rounded">
            <Card.Body>
                <Carousel
                    activeIndex={activeIndex}
                    onSelect={handleSelect}
                    pause='hover'
                    interval={null}
                    indicators={false}
                    fade={true}
                    className='my-carousel bg-opacity-100 mb-4'
                >
                    {/* Map through product images to create carousel items */}
                    {product?.imageArray.map((imageUrl, index) => (
                        <Carousel.Item key={index}>
                            <Link to={`/product/${productId}`}>
                                <Image src={imageUrl} alt={product.name} fluid />
                            </Link>
                        </Carousel.Item>
                    ))}
                </Carousel>
                <div className="d-flex justify-content-center align-items-center">
                    {/* Previous thumbnail arrow */}
                    <button className="thumbnail-scroll-button" onClick={handlePrevThumbnail}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    {/* Thumbnails of product images */}
                    <div className="thumbnail-wrapper">
                        <div
                            className="thumbnail-container"
                            ref={thumbnailRef}
                            onMouseDown={handleMouseDown}
                            onMouseLeave={handleMouseLeave}
                            onMouseUp={handleMouseUp}
                            onMouseMove={handleMouseMove}
                        >
                            {product?.imageArray.map((imageUrl, index) => (
                                <Image
                                    key={index}
                                    src={imageUrl}
                                    alt={product.name}
                                    onClick={() => handleSelect(index)}
                                    className="thumbnail img-fluid"
                                    draggable="false" // Disable dragging
                                    style={{
                                        border: activeIndex === index ? '2px solid #000' : 'none', // Highlight the active thumbnail
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                    {/* Next thumbnail arrow */}
                    <button className="thumbnail-scroll-button" onClick={handleNextThumbnail}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>
            </Card.Body>
        </Card>
    );
};

export default ProductsItemsCarousel;
