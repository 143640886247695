import React from 'react';
import { Card, Table, Form, Button } from "react-bootstrap";
import Favourite from "../components/Favourite";
import { calculateDiscountedPrice } from "../utils/discountUtils";

const ProductDetails = ({
                            product,
                            selectedSize,
                            setSelectedSize,
                            availableStockForSize,
                            setAvailableStockForSize,
                            qty,
                            setQty,
                            addToCartHandler,
                            productId,
                            showDiscount
                        }) => {
    // Check if product and discount exist
    const discount = product?.discount ?? 0;
    const discountedPrice = calculateDiscountedPrice(product?.price ?? 0, discount);

    // Status row: Handle overall product stock (countInStock)
    const rows = [
        {
            label: 'Price:',
            value: showDiscount && product.isClearance && discount > 0 ? (
                <>
                    <span style={{ textDecoration: 'line-through' }}>£{product.price.toFixed(2)}</span>
                    <br />
                    <span>Now: £{discountedPrice.toFixed(2)}</span>
                    <br />
                    <span>Save {discount}%</span>
                </>
            ) : `£${product.price.toFixed(2)}`
        },
        {
            label: 'Status:',
            value: (
                <strong style={{ color: (product.countInStock !== null && product.countInStock > 0) ? 'green' : 'orange' }}>
                    {(product.countInStock !== null && product.countInStock > 0)
                        ? `In Stock (${product.countInStock} available)`
                        : "Awaiting Stock"}
                </strong>
            )
        }
    ];

    // Handle T-Shirts with sizes
    if (product && product.countInStock > 0) {
        if (product.productType === 'T-Shirts' && product.sizeArray && product.sizeArray.length > 0) {
            rows.push({
                label: 'Size:',
                value: (
                    <Form.Control
                        as='select'
                        value={selectedSize}
                        onChange={(e) => {
                            const size = e.target.value;
                            setSelectedSize(size);
                            const sizeDetails = product.sizeArray.find(s => s.size === size);
                            if (sizeDetails) {
                                setAvailableStockForSize(sizeDetails.tshirtInStock);
                            }
                        }}
                        style={{ display: 'block', margin: '0 auto', textAlign: 'center' }}
                    >
                        <option value=''>Select a size</option>
                        {product.sizeArray.map((sizeInfo) => (
                            <option
                                key={sizeInfo._id}
                                value={sizeInfo.size}
                                disabled={sizeInfo.tshirtInStock === 0 || sizeInfo.tshirtInStock === null}
                            >
                                {sizeInfo.size}
                                {sizeInfo.tshirtInStock > 0
                                    // ? ` (${sizeInfo.tshirtInStock} available)`
                                    ? ''
                                    : ' (Awaiting Stock)'}
                            </option>
                        ))}
                    </Form.Control>
                )
            });
        }

        // Add Quantity selection for available stock
        rows.push({
            label: 'Qty:',
            value: (
                <div className="centered-select">
                    <select
                        value={qty}
                        onChange={(e) => setQty(Number(e.target.value))}
                        style={{ width: '100%', textAlignLast: 'center' }}
                    >
                        {[...Array(product.productType === 'T-Shirts' ? availableStockForSize : product.countInStock).keys()].map(x => (
                            <option key={x + 1} value={x + 1}>
                                {x + 1}
                            </option>
                        ))}
                    </select>
                </div>
            )
        });
    }

    const isEvenRows = rows.length % 2 === 0;

    return (
        <Card className="my-3">
            <Card.Body>
                <Table bordered responsive style={{ boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', borderRadius: '10px', overflow: 'hidden' }}>
                    <tbody>
                    {rows.map((row, index) => (
                        <tr key={index} style={{ backgroundColor: (index + (isEvenRows ? 0 : 1)) % 2 === 0 ? '#ffffff' : '#e3f2fd' }}>
                            <td style={{ verticalAlign: 'middle', textAlign: 'center', color: 'black' }}>{row.label}</td>
                            <td style={{ color: 'black' }}>{row.value}</td>
                        </tr>
                    ))}
                    <tr style={{ backgroundColor: (rows.length + (isEvenRows ? 0 : 1)) % 2 === 0 ? '#ffffff' : '#e3f2fd' }}>
                        <td className="text-center" style={{ padding: '20px 0' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Favourite productId={productId} />
                            </div>
                        </td>
                        <td className="text-center" style={{ padding: '20px 0' }}>
                            <Button
                                onClick={addToCartHandler}
                                className="btn-block blue-outline"
                                type="button"
                                disabled={
                                    product.productType === 'T-Shirts'
                                        ? availableStockForSize === 0
                                        : product.countInStock === 0
                                }
                            >
                                Add To Cart
                            </Button>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};

export default ProductDetails;
