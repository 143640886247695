import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Carousel, Modal, Button } from 'react-bootstrap';
import {
    useGetTopChoicesQuery,
    useGetNewArrivalsQuery,
    useGetClearancesQuery,
    useGetFeaturedQuery
} from '../slices/productsApiSlice';
import HeaderNav from '../components/HeaderNav';
import Loader from '../components/Loader';
import Message from '../components/Message';
import ProductCard from '../components/ProductCard';
import ImageLayout from '../components/ImageLayout';
import ProductCardCarousel from '../components/ProductCardCarousel';
import StayTop from '../components/StayTop';
import Products from '../components/Products';
import { Link } from 'react-router-dom';

const LandingPage = () => {
    // API data queries
    const { data: topChoices, isLoading: loadingTopChoices, error: errorTopChoices } = useGetTopChoicesQuery();
    const { data: newArrivals, isLoading: loadingNewArrivals, error: errorNewArrivals } = useGetNewArrivalsQuery();
    const { data: clearances, isLoading: loadingClearances, error: errorClearances } = useGetClearancesQuery();
    const { data: featuredProducts, isLoading: loadingFeaturedProducts, error: errorFeaturedProducts } = useGetFeaturedQuery();

    const [description, setDescription] = useState('');
    const [chunkSize, setChunkSize] = useState(4);
    const [showCookieModal, setShowCookieModal] = useState(true);

    // Dynamically load the TrustBox script
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
        script.async = true;
        document.head.appendChild(script);

        return () => {
            // Clean up the script if the component unmounts
            document.head.removeChild(script);
        };
    }, []);

    // Handle dynamic chunk sizing for responsive layout
    useEffect(() => {
        const updateChunkSize = () => {
            if (window.innerWidth < 992) {
                setChunkSize(2);
            } else {
                setChunkSize(4);
            }
        };
        window.addEventListener('resize', updateChunkSize);
        updateChunkSize(); // Initial check
        return () => window.removeEventListener('resize', updateChunkSize);
    }, []);

    // Function to chunk the array into smaller arrays
    const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };

    const newArrivalsChunks = chunkArray(newArrivals || [], chunkSize);

    // Handle cookie modal actions
    const handleAcceptCookies = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        setShowCookieModal(false);
    };

    const handleRejectCookies = () => {
        localStorage.setItem('cookiesAccepted', 'false');
        setShowCookieModal(false);
    };

    useEffect(() => {
        const cookiesAccepted = localStorage.getItem('cookiesAccepted');
        if (cookiesAccepted) {
            setShowCookieModal(false);
        }
    }, []);

    return (
        <>
            <StayTop />
            {/* TrustBox widget - Review Collector */}

            {/* End TrustBox widget */}
            <HeaderNav />
            <ImageLayout onIconClick={(newDescription) => setDescription(newDescription)} />

            <Container fluid>
                <section className="landing-section">
                    <div className="banner">
                        <Container>
                            <h1>Welcome to Retrodayz</h1>
                        </Container>
                    </div>
                    {loadingTopChoices ? (
                        <Loader />
                    ) : errorTopChoices ? (
                        <Message variant="danger">{errorTopChoices.message || errorTopChoices.data.message || "An error occurred"}</Message>
                    ) : (
                        <div className="product-grid">
                            {topChoices?.map((product) => (
                                <ProductCardCarousel key={product._id} product={product} />
                            ))}
                        </div>
                    )}
                </section>

                <section className="landing-section">
                    <div className="banner2">
                        <Container>
                            <h1>🔥🔥 New Arrivals 🔥🔥</h1>
                        </Container>
                    </div>
                    {loadingNewArrivals ? (
                        <Loader />
                    ) : errorNewArrivals ? (
                        <Message variant="danger">{errorNewArrivals.message || errorNewArrivals.data.message || "An error occurred"}</Message>
                    ) : (
                        <Carousel controls indicators={true} className="carousel-dark custom-carousel-indicators" interval={3000}>
                            {newArrivalsChunks.map((chunk, index) => (
                                <Carousel.Item key={index}>
                                    <Row
                                        id="targetRow"
                                        className={clearances?.length < 4 ? 'justify-content-center' : ''}
                                    >
                                        {chunk.map((product) => (
                                            <Col key={product._id} xs={12} sm={6} md={6} lg={3} xl={3}>
                                                <Products product={product} showDiscount={product.isClearance} />
                                            </Col>
                                        ))}
                                    </Row>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    )}
                </section>

                <section className="landing-section">
                    <div className="banner">
                        <Container>
                            <h1>⭐⭐ Retrodayz products of the week! ⭐⭐</h1>
                        </Container>
                    </div>
                    {loadingFeaturedProducts ? (
                        <Loader />
                    ) : errorFeaturedProducts ? (
                        <Message variant="danger">{errorFeaturedProducts.message || errorFeaturedProducts.data.message || "An error occurred"}</Message>
                    ) : (
                        <Row id="targetRow" className={'justify-content-center'}>
                            {featuredProducts?.map((product) => (
                                <Col key={product._id} xs={12} sm={6} md={6} lg={3} xl={3}>
                                    <Products product={product} showDiscount={product.isClearance} style={{ width: '80%', height: 'auto' }} />
                                </Col>
                            ))}
                        </Row>
                    )}
                </section>

                <section className="landing-section">
                    <div className="banner2">
                        <Container>
                            <h1>Retrodayz offer of the week!</h1>
                        </Container>
                    </div>
                    {loadingClearances ? (
                        <Loader />
                    ) : errorClearances ? (
                        <Message variant="danger">
                            {errorClearances.message || errorClearances.data.message || "An error occurred"}
                        </Message>
                    ) : (
                        <Carousel controls indicators={true} className="carousel-dark custom-carousel-indicators" interval={6000}>
                            {chunkArray(clearances || [], chunkSize).map((chunk, index) => (
                                <Carousel.Item key={index}>
                                    <Row id="targetRow" className={'justify-content-center'}>
                                        {chunk.map((product) => (
                                            <Col key={product._id} xs={12} sm={6} md={6} lg={3} xl={3}>
                                                <Products product={product} showDiscount={product.isClearance} style={{ width: '80%', height: 'auto' }} />
                                            </Col>
                                        ))}
                                    </Row>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    )}
                </section>

            </Container>

            <Modal show={showCookieModal} onHide={handleAcceptCookies}>
                <Modal.Header closeButton>
                    <Modal.Title>Cookie Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    We use cookies to improve your experience on our site and to show you personalized advertising. By accepting, you agree to our use of cookies as described in our <Link to="/cookie">Cookie Policy</Link>.
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ backgroundColor: '#3E6D9C', borderColor: '#FD7014' }} onClick={handleAcceptCookies}>
                        Accept
                    </Button>
                    <Button style={{ backgroundColor: '#FD7014', borderColor: '#3E6D9C' }} onClick={handleRejectCookies}>
                        Reject
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default LandingPage;
