import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useNavigate, useSubmit} from "react-router-dom";
import { savePaymentMethod } from "../slices/cartSlice";
import { Form, Button, Col } from "react-bootstrap";
import FormContainer from "../components/FormContainer";
import CheckoutSteps from "../components/CheckoutSteps";
import StayTop from "../components/StayTop";
const PaymentScreen = () => {
    const [paymentMethod, setPaymentMethod] = useState('PayPal');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const cart = useSelector(state => state.cart);
    const { shippingAddress } = cart;

    useEffect(() => {
        if (!shippingAddress.address) {
            navigate('/shipping')
        }
    }, [shippingAddress, navigate])

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(savePaymentMethod(paymentMethod));
        navigate('/placeorder')
    }

    return (
        <FormContainer>
            <CheckoutSteps Step1 Step2 Step3 />
            <h1>PaymentScreen</h1>
            <Form onSubmit={ submitHandler }>
                <Form.Group>
                    <Form.Label as='legend'>Select Method</Form.Label>
                    <Col>
                        <Form.Check
                            className={'my-3'}
                            type='radio'
                            label='PayPal or Credit Card'
                            id='PayPal'
                            name='paymentMethod'
                            value='PayPal'
                            checked
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        ></Form.Check>
                    </Col>
                </Form.Group>
                <Button type='submit' variant='primary' className='my-2 blue-outline'>
                    Continue
                </Button>
            </Form>
            <StayTop />
        </FormContainer>
    );
};

export default PaymentScreen;