import { apiSlice } from "./apiSlice";
import {ORDERS_URL, PAYPAL_URL} from "../constants";

export const ordersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createOrder: builder.mutation({
            query: (order) => ({
                url: ORDERS_URL,
                method: "POST",
                body: {...order}
            }),
        }),
        cancelOrder: builder.mutation({
            query: (id) => ({
                url: `${ORDERS_URL}/${id}`,
                method: 'PUT',
            }),
        }),
        getOrderDetails: builder.query({
            query: (id) => ({
                url: `${ORDERS_URL}/${id}`, // /api/orders/:id - SC
                method: "GET", //Get is default is there for info - SC
            }),
            keepUnusedDataFor: 5, // keep the data for 5 seconds - SC
        }),
        payOrder: builder.mutation({
            query: ({orderId, details}) => ({
                url: `${ORDERS_URL}/${orderId}/pay`,
                method: 'PUT',
                body: {...details},
            }),
        }),
        getPaypalClientId: builder.query({
            query: () => ({
              url: PAYPAL_URL,
        }),
        keepUnusedDataFor: 5, // keep the data for 5 seconds - SC
        }),
        getMyOrders: builder.query({
            query: () => ({
                url: `${ORDERS_URL}/mine`,
            }),
            keepUnusedDataFor: 5, // keep the data for 5 seconds - SC
        }),
        getOrders: builder.query({
            query: () => ({
                url: ORDERS_URL,
            }),
            keepUnusedDataFor: 5, // keep the data for 5 seconds - SC
        }),
        deliverOrder: builder.mutation({
            query: (orderId) => ({
                url: `${ORDERS_URL}/${orderId}/deliver`,
                method: 'PUT',
            }),
        }),
    }),
});

export const {
    useCreateOrderMutation,
    useGetOrderDetailsQuery,
    usePayOrderMutation,
    useGetPaypalClientIdQuery,
    useGetMyOrdersQuery,
    useGetOrdersQuery,
    useDeliverOrderMutation,
    useCancelOrderMutation
    } =
    ordersApiSlice;