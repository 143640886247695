import ButtonMailto from '../components/ButtonMailto';
import {Card } from 'react-bootstrap';
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareFacebook} from "@fortawesome/free-brands-svg-icons";
import {faXTwitter} from "@fortawesome/free-brands-svg-icons";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import StayTop from "../components/StayTop";
import {useEffect} from "react";
import { useNavigate, Link } from "react-router-dom";
const Contact = () => {
    const navigate = useNavigate();
    return (
        <>
            <StayTop />
            <Link className="btn btn-light my-3 orange-outline" onClick={() => navigate(-1)}>
                Go Back
            </Link>
        <div>
          <Card className="my-3 contact">
           <Card.Body>
            <h4>Contact us</h4>
            <h5></h5>
            <p>
                Address: <br /> RetroDayz <br /> 54 St James Street <br /> Liverpool <br /> L1 0AB  <br />United Kingdom
            </p>
            {/*<p>Phone: 0151 281 6162</p>*/}
               <div>
                   <p>If you require assistance with your account, products, or have any general inquiries, please do not hesitate to contact us:</p>
                   <p>
                       <a href="mailto:support@retrodayz.co.uk">
                           <FontAwesomeIcon icon={faEnvelope} className="footer-social-icon" style={{ fontSize: '36px', color: "black" }} />
                           Contact us at support@retrodayz.co.uk
                       </a>
                   </p>
                   <p>For further updates and insights, connect with us on our social media platforms:</p>
                   {/* Social media icons can be placed here */}
               <a href="https://www.instagram.com/retrodayz_/" target="_blank" rel="noopener noreferrer">
                   <FontAwesomeIcon icon={faInstagram} className="footer-social-icon" style={{ fontSize: '36px', color: "black" }} />
               </a>
               <a href="https://www.facebook.com/profile.php?id=61558538470404" target="_blank" rel="noopener noreferrer">
                   <FontAwesomeIcon icon={faSquareFacebook} className="footer-social-icon" style={{ fontSize: '36px', color: "black" }} />
               </a>
               <a href="https://twitter.com/retrodayz_" target="_blank" rel="noopener noreferrer">
                   <FontAwesomeIcon icon={faXTwitter} className="footer-social-icon" style={{ fontSize: '36px', color: "black" }} />
               </a>
               </div>
           </Card.Body>
          </Card>

            <Card className="my-3 contact">
             <Card.Body>
            <h4>Directions</h4>
            {/*<p>*/}
            {/*    54 St. James Street is located between Norfolk and Watkinson Street,*/}
            {/*    Liverpool,*/}
            {/*    L1 0AB, adjacent to Chinatown and Liverpool One. Please note it should NOT be confused with James Street, Liverpool.*/}
            {/*</p>*/}
            <p>

            <h5>Walking</h5>
            <ul>
                <li>10 minutes’ walk from Liverpool One – via Park Lane</li>
            </ul>
            </p>
                 <hr className="my-divider" />
            <h5>Bicycle</h5>
            <p>
                There are bicycle racks available on the street outside the building.
            <ul>
                <li>Bicycle store available for cyclists using the building.</li>
            </ul>
            </p>
                 <hr className="my-divider" />
            <h5>Train</h5>
            <p>
            <ul>
                <li>Central Train Station (approx. 15-minute walk)</li>
                <li>James Street Train Station (approx. 15-minute walk)</li>
                <li>Lime Street Train Station (approx. 20-minute walk)</li>
            </ul>
            </p>
                 <hr className="my-divider" />
            <h5>Bus</h5>
            <p>
                The following bus routes stop on Duke Street:
            <ul>
                <li>Paradise St Bus Station (approx. 10-minute walk)</li>
                {/* List other bus routes */}
            </ul>
            </p>
                 <hr className="my-divider" />
            <h5>By car</h5>
            <p>
                Please be aware parking in the area is limited so please allow plenty of time to find a space if you’re planning on driving to the venue.
            </p>
            <p>
                Free on-street parking is available subject to availability with a 2-hour maximum stay between 8am and 6pm in any parking bay marked “Business Permit Bay”.
            </p>
             </Card.Body>
            </Card>
            <Card className="my-3 contact">
             {/*<Card.Body>*/}
             {/*   <h3>Shop Hours</h3>*/}
             {/*   <p>Monday - Saturday 9am to 6pm</p>*/}
             {/*   <p>Sunday - Closed</p>*/}
             {/*</Card.Body>*/}
            </Card>
        </div>
        </>
    );
};
export default Contact;