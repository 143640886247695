import React from "react";
import { useNavigate, Link } from "react-router-dom";
import StayTop from "../components/StayTop";
// import aboutImage from "./about-image.jpg"; // Import your image here
import about from "../assets/About.png";


const About = () => {
    const navigate = useNavigate();
    return (

        <>
            <StayTop />
            <Link className="btn btn-light my-3 orange-outline" onClick={() => navigate(-1)}>
                Go Back
            </Link>
            <div style={{ marginBottom: "40px" }}>
            <div className="container">
            <section className="about-section">
                <div className="image-card">
                    {/*<img src={about} alt="About Retrodayz" className="about-image" />*/}
                </div>
                <div className="about-content">
                    <h2 className="about-heading">Welcome to Retrodayz - Your Nostalgia Haven!</h2>
                    <p>
                        Dive into the golden days of gaming and pop culture with Retrodayz, where memories are just a click away.
                    </p>
                    <p>
                        We are a passionate team of enthusiasts, dedicated to bringing the past right to your doorstep.
                        Our carefully curated collection features a range of merchandise that will grow and grow as we become more
                        established, to include t-shirts, mugs, cuddly plush, collectable items and lighting, all inspired by the
                        iconic eras of movie, gaming and timeless TV.
                    </p>
                    <p>
                        As a small startup, we resonate with the collector's spirit and aim to provide high-quality,
                        unique products that celebrate the classics of the '80s, '90s, and early '00s.
                        Whether you're a chilled gamer, a movie buff, or someone who appreciates the charm of yesteryears,
                        we've got something special for you.
                    </p>
                    <p>
                        Our journey began as a small group of friends reminiscing about the good old days, trading games,
                        and sharing stories of our favorite movie scenes. This shared passion led to the creation of Retrodayz,
                        a place where like-minded hobbyists can relive the thrill of retro gaming and pop culture.  The site is
                        evolving and we'll be adding more and more products and features as we go along.
                    </p>
                </div>
            </section>

            <section className="why-choose-section">
                <h3 className="section-heading">Why Choose Retrodayz?</h3>
                <ul className="why-choose-list">
                    <li className="why-choose-item">Our Collection: From retro and modern gaming, TV, or movies, our products are designed to evoke nostalgia and bring a piece of history into your everyday life.</li>
                    <li className="why-choose-item">Quality You Can Trust: We understand the value of every item we sell and ensure the highest quality standards for all our products.</li>
                    <li className="why-choose-item">Community of Enthusiasts: Join our community of retro lovers! Share stories, participate in discussions, and stay updated with the latest additions to our collection at our social feeds located in the footer.</li>
                    <li className="why-choose-item">Customer-Centric Service: Your satisfaction is our priority. We're here to assist you with any queries and ensure a smooth, enjoyable shopping experience.</li>
                </ul>
            </section>

            <section className="testimonial-section">
                <h3 className="section-heading">What Our Customers Say</h3>
                <div className="testimonial">
                    <blockquote className="testimonial-quote">
                        "I love Retrodayz! Their products always bring back fond memories of my childhood. Highly recommended!"
                    </blockquote>
                    <p className="testimonial-author">- Phil Williams</p>
                </div>
                {/*<div className="testimonial">*/}
                {/*    <blockquote className="testimonial-quote">*/}
                {/*        "I have enjoyed watching this size grow and become astablished, its a great site and I hope to see it develop over time!"*/}
                {/*    </blockquote>*/}
                {/*    <p className="testimonial-author">- Alan Turner</p>*/}
                {/*</div>*/}
                {/* Additional testimonials can be added here */}
            </section>

            <section className="cta-section">
                <h3 className="section-heading">Embark on a Nostalgic Journey with Retrodayz!</h3>
                <p>Explore a world of retro gaming, classic movies, and series merchandise. After all, nostalgia never goes out of style!</p>
                <div style={{ marginBottom: '20px' }}>
                    <a href="/" className="cta-button">Shop Now</a>
                </div>
            </section>

            <section className="thank-you-section">
                <h3 className="section-heading">Thank You for Visiting Retrodayz!</h3>
                <p>We appreciate your interest in our brand. Feel free to reach out to us if you have any questions or feedback.</p>
                <p>Happy shopping!</p>
                <div style={{ marginBottom: '20px' }}>
                    <a href="/" className="cta-button"></a>
                 </div>
                </section>
                </div>
            </div>
        </>
    );
};

export default About;
