import StayTop from "../components/StayTop";
import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

const About = () => {
    const navigate = useNavigate();
    return (
        <>
            <StayTop />
            <Link className="btn btn-light my-3 orange-outline" onClick={() => navigate(-1)}>
                Go Back
            </Link>
            <div>
                <h3>Cookies Policy</h3>
                <p>Last updated: 1st July 2024</p>
                <p>This Cookies Policy explains what cookies are and how we use them on our website. It should be read alongside our Privacy Policy. By continuing to browse or use our services, you agree to our use of cookies as described in this policy.</p>

                <h4>What are Cookies?</h4>
                <p>Cookies are small text files that are placed on your device (computer, smartphone, or other electronic devices) when you visit a website. They help the website to recognize your device and remember information about your visit.</p>

                <h4>How We Use Cookies</h4>
                <p>We use cookies for several purposes, including:</p>
                <ul>
                    <li><strong>Necessary Cookies:</strong> These cookies are essential for you to browse our website and use its features. Without these cookies, certain services cannot be provided.</li>
                    <li><strong>Preference Cookies:</strong> These cookies allow our website to remember choices you have made in the past, like your language preference or the region you are in.</li>
                    <li><strong>Analytics Cookies:</strong> These cookies collect information about how you use our website, such as which pages you visited and which links you clicked on. We use this information to improve our website and services.</li>
                    <li><strong>Advertising Cookies:</strong> These cookies are used to deliver advertisements relevant to you and your interests. They also help limit the number of times you see an advertisement and measure the effectiveness of advertising campaigns.</li>
                </ul>

                <h4>Types of Cookies We Use</h4>
                <p>We use the following types of cookies:</p>
                <ul>
                    <li><strong>Session Cookies:</strong> These cookies are temporary and expire once you close your browser.</li>
                    <li><strong>Persistent Cookies:</strong> These cookies remain on your device for a set period or until you delete them. They are used to remember your preferences for future visits.</li>
                    <li><strong>Third-Party Cookies:</strong> These cookies are set by third parties, such as analytics services or advertisers. We do not control these cookies, and you should check the third-party websites for more information about these cookies.</li>
                </ul>

                <h4>Your Choices Regarding Cookies</h4>
                <p>You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences by:</p>
                <ul>
                    <li><strong>Browser Settings:</strong> Most web browsers allow you to control cookies through their settings. You can set your browser to refuse cookies or delete cookies that have already been set. However, if you disable cookies, some features of our website may not function properly.</li>
                    <li><strong>Opt-Out Mechanisms:</strong> Some third-party services provide mechanisms to opt-out of data collection through cookies. For example, you can use the Network Advertising Initiative’s opt-out tool or the Digital Advertising Alliance’s opt-out tool.</li>
                </ul>

                <h4>Changes to This Cookies Policy</h4>
                <p>We may update our Cookies Policy from time to time to reflect changes in our practices or legal requirements. The date at the top of this policy indicates when it was last updated. We encourage you to review this policy periodically to stay informed about our use of cookies.</p>

                <h4>Contact Us</h4>
                <p>If you have any questions about our use of cookies, please contact us at:</p>
                <p>Retrodayz Ltd<br />
                    support@retrodayz.co.uk</p>
            </div>
        </>
    );
};

export default About;