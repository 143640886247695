import {PRODUCTS_URL, UPLOAD_URL} from "../constants";
import { apiSlice } from "./apiSlice";


export const productsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: ({keyword, pageNumber}) => ({
                url: PRODUCTS_URL,
                params: {keyword, pageNumber
                    }
            }),
            keepUnusedDataFor: 5,
        }),
        getProductsNav: builder.query({
            query: ({keyword, pageNumber}) => ({
                url: PRODUCTS_URL,
                params: {keyword, pageNumber
                }
            }),
            keepUnusedDataFor: 5,
        }),
        getProductDetails: builder.query({
            query: (productId) =>({
                url: `${PRODUCTS_URL}/${productId}`,
            }),
            providesTags: ['Products'],
            keepUnusedDataFor: 5,
        }),
        createProduct: builder.mutation({
            query: () => ({
                url: PRODUCTS_URL,
                method: "POST",
            }),
            invalidatesTags: ['Product'],
        }),
        updateProduct: builder.mutation({
            query: (data) => ({
                url: `${PRODUCTS_URL}/${data.productId}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ['Products'],
        }),
        uploadProductImage: builder.mutation({
            query: (data) => ({
                url: `${UPLOAD_URL}`,
                method: "POST",
                body: data,
            }),
        }),
        deleteProduct: builder.mutation({
            query: (productId) => ({
                url: `${PRODUCTS_URL}/${productId}`,
                method: "DELETE",
            }),
        }),
        createReview: builder.mutation({
            query: (data) => ({
                url: `${PRODUCTS_URL}/${data.productId}/reviews`,
                method: "POST",
                body: data,
            }),
        }),
        getTopProducts: builder.query({
            query: () => ({
                url: `${PRODUCTS_URL}/top`,
            }),
            keepUnusedDataFor: 5,
        }),
        getFontProducts: builder.query({
            query: () => ({
                url: `${PRODUCTS_URL}/font`,
            }),
            keepUnusedDataFor: 5,
        }),
        getImageProducts: builder.query({
            query: () =>({
                url: `${PRODUCTS_URL}`,
            }),
            keepUnusedDataFor: 5,
        }),
        getMyWishlistQuery: builder.query({
            query: () => ({
                url: `${PRODUCTS_URL}/wishlist`,
            }),
            keepUnusedDataFor: 5,
        }),
        // getFontProducts: builder.query({
        //     query: () => ({
        //         url: `${PRODUCTS_URL}/font`,
        //     }),
        //     keepUnusedDataFor: 5,
        // }),
        getTopChoices: builder.query({
            query: () => ({
                url: `${PRODUCTS_URL}/top-choices`,
            }),
            keepUnusedDataFor: 5,
        }),
        getNewArrivals: builder.query({
            query: () => ({
            url: `${PRODUCTS_URL}/new-arrivals`,
        }),
            keepUnusedDataFor: 5,
        }),
        getClearances: builder.query({
            query: () => ({
            url: `${PRODUCTS_URL}/clearances`,
        }),
            keepUnusedDataFor: 5,
        }),
        getFeatured: builder.query({
            query: () => ({
            url: `${PRODUCTS_URL}/featured`,
        }),
            keepUnusedDataFor: 5,
        }),
    }),
});

export const { useGetProductsQuery,
    useGetProductDetailsQuery,
    useGetProductsNavQuery,
    useCreateProductMutation,
    useUpdateProductMutation,
    useUploadProductImageMutation,
    useDeleteProductMutation,
    useGetTopProductsQuery,
    useGetFontProductsQuery,
    useGetImageProductsQuery,
    useCreateReviewMutation,
    useGetMyWishlistQuery,
    useGetTopChoicesQuery,
    useGetNewArrivalsQuery,
    useGetClearancesQuery,
    useGetFeaturedQuery,
    } = productsApiSlice;