
import {useEffect} from "react";

const About = () => {
    return (
        <div>
            <p></p>
            <h3>Don't worry...   help is coming soon!!</h3>

        </div>
    );
};
export default About;