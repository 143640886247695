import React, { useState, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { useResetPasswordUserMutation } from '../slices/usersApiSlice';
import StayTop from '../components/StayTop';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const ResetPasswordForm = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [passwordErrors, setPasswordErrors] = useState([]);
    const [resetPasswordUser, { isLoading }] = useResetPasswordUserMutation();

    // useCallback to ensure this function is memoized and not recreated on every render
    const validatePasswordComplexity = useCallback((password) => {
        const errors = [];
        if (!/[a-z]/.test(password)) errors.push("At least one lowercase letter");
        if (!/[A-Z]/.test(password)) errors.push("At least one uppercase letter");
        if (!/\d/.test(password)) errors.push("At least one digit");
        if (!/[!@#$%^&*]/.test(password)) errors.push("At least one special character (!@#$%^&*)");
        if (password.length < 8) errors.push("Minimum length of 8 characters");
        return errors;
    }, []); // Dependencies are empty as this function does not depend on any state or props

    // useCallback to ensure this function is memoized and not recreated on every render
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setPasswordErrors(validatePasswordComplexity(newPassword));
    };
    // useCallback to ensure this function is memoized and not recreated on every render
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };
    // Prevent pasting into the password field
    const handlePasswordPaste = (e) => {
        e.preventDefault();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }
        if (passwordErrors.length > 0) {
            passwordErrors.forEach(error => toast.error(error));
            return;
        }

        try {
            await resetPasswordUser({ token, password }).unwrap();
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                toast.error('An error occurred while resetting your password. Please try again later.');
            } else {
                toast.success('Your password has been reset successfully!');
                navigate('/login');
            }
        }


    };

    return (
      <>
        <StayTop />
          <Link to="/" className="btn btn-light my-3 orange-outline">
              Go Home
          </Link>
        <Form onSubmit={handleSubmit}>
            <h4>Reset Your Password</h4>
            <Form.Group className="mb-3" controlId="password">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                    <div style={{ width: '30%' }}> {/* Adjust the width here */}
                        <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter new password"
                            value={password}
                            onChange={handlePasswordChange}
                            onPaste={handlePasswordPaste} // Disable paste functionality
                            required
                        />
                    </div>
                    <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                </InputGroup>
                {passwordErrors.length > 0 && (
                    <div style={{ color: 'red' }}>
                        {passwordErrors.map((error, index) => <div key={index}>{error}</div>)}
                    </div>
                )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="confirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <InputGroup>
                    <div style={{ width: '30%' }}> {/* Adjust the width here */}
                        <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Confirm new password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            onPaste={handlePasswordPaste} // Disable paste functionality
                            required
                        />
                    </div>
                    <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                </InputGroup>
            </Form.Group>

            {isLoading && <Loader />}
            <Button
                type="submit"
                className="mt-2 blue-outline"
                disabled={isLoading || passwordErrors.length > 0
                    || password !== confirmPassword || !password || !confirmPassword}>
                Reset Password
            </Button>
        </Form>
      </>
    );
};

export default ResetPasswordForm;
