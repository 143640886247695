import { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import { useVerifyEmailMutation } from '../slices/usersApiSlice';

const VerifyEmailScreen = () => {
    console.log('VerifyEmailScreen component loaded'); // Log when the component is loaded

    const location = useLocation();
    console.log(`Location object: ${JSON.stringify(location)}`); // Log the entire location object
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    console.log(`Token from URL: ${token}`); // Log the token

    const [verifyEmail, { data, error, isLoading, isSuccess }] = useVerifyEmailMutation();
    console.log('useVerifyEmailMutation executed'); // Log when the mutation is executed

    const [message, setMessage] = useState('Email verification in progress...');

    useEffect(() => {
        if (token) {
            console.log(`Sending token for verification: ${token}`); // Log the token being sent for verification
            verifyEmail({ token });
        }
    }, [token, verifyEmail]);

    useEffect(() => {
        console.log('useEffect triggered'); // Log when useEffect is triggered

        if (isLoading) {
            setMessage('Email verification in progress...');
        } else if (isSuccess) {
            console.log('Verification successful');
            setMessage('Thank you for confirming your email. Please select the button below to sign in.');
        } else if (error) {
            console.log('Error encountered:', error); // Log the error
            setMessage(error?.data?.message || error.error);
            toast.error(error?.data?.message || error.error);
        }
    }, [isLoading, isSuccess, error]);

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <div>
                    <p className="mt-2" >  {message}</p>
                    {isSuccess && (
                        <Link to="/login" className="btn btn-primary my-2 blue-outline">
                            Sign in
                        </Link>
                    )}
                </div>
            )}
        </div>
    );
};

export default VerifyEmailScreen;
