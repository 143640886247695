import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, ListGroup, Image, Form, Button, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { FaTrashAlt } from 'react-icons/fa';
import Message from "../components/Message";
import { addToCart, removeFromCart } from "../slices/cartSlice";
import { calculateDiscountedPrice, getDiscountDisplayPercentage } from "../utils/discountUtils";

const CartScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const cart = useSelector((state) => state.cart);
    const { cartItems } = cart;

    const checkoutHandler = () => {
        navigate('/login?redirect=/shipping');
    };

    const addToCartHandler = async (product, qty) => {
        dispatch(addToCart({ ...product, qty }));
    };

    const removeFromCartHandler = (id, size) => {
        dispatch(removeFromCart({ _id: id, size }));
    };

    return (
        <>
            <Link className="btn btn-light my-3 orange-outline" onClick={() => navigate(-1)}>
                Go Back
            </Link>
            <Row>
                <Col md={12} lg={8}>
                    <h1 style={{ marginBottom: '20px' }}>Shopping Basket</h1>
                    {cartItems.length === 0 ? (
                        <Message>Your basket is empty <Link to='/'>Go Back</Link></Message>
                    ) : (
                        <ListGroup variant='flush'>
                            <ListGroup.Item className="border-0">
                                <Row>
                                    <Col xs={6} md={3} lg={2}><h6></h6></Col>
                                    <Col md={2}><h6>Name</h6></Col>
                                    <Col md={2}><h6>Price</h6></Col>
                                    <Col md={2}><h6>Size</h6></Col>
                                    <Col xs={3} md={2}><h6>Qty</h6></Col>
                                    <Col xs={3} md={1}><h6>Remove</h6></Col>
                                </Row>
                            </ListGroup.Item>
                            {cartItems.map((item) => {
                                const discountedPrice = calculateDiscountedPrice(item.price, item.discount ?? 0);
                                return (
                                    <ListGroup.Item key={item._id}>
                                        <Row>
                                            <Col xs={6} md={3} lg={2}>
                                                <Image src={item.image} alt={item.name} fluid rounded />
                                            </Col>
                                            <Col md={2}>
                                                <Link to={`/product/${item._id}`}>{item.name}</Link>
                                            </Col>
                                            <Col md={2}>
                                                {item.isClearance ? (
                                                    <>
                                                        <span style={{ textDecoration: 'line-through' }}>£{item.price.toFixed(2)}</span>
                                                        <br />
                                                        <span>£{discountedPrice.toFixed(2)}</span>
                                                    </>
                                                ) : (
                                                    <span>£{item.price.toFixed(2)}</span>
                                                )}
                                            </Col>
                                            {item.size ? (
                                                <Col md={2}>
                                                    Size: {item.size}
                                                </Col>
                                            ) : (
                                                <Col md={2}>
                                                    <div className="empty-space">N/A</div>
                                                </Col>
                                            )}
                                            <Col xs={3} md={2}>
                                                <Form.Control
                                                    as='select'
                                                    value={item.qty}
                                                    onChange={(e) => addToCartHandler(item, Number(e.target.value))}
                                                >
                                                    {[...Array(item.countInStock).keys()].map((x) => (
                                                        <option key={x + 1} value={x + 1}>
                                                            {x + 1}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Col>
                                            <Col xs={3} md={1}>
                                                <Button type='button' variant='light' onClick={() =>
                                                    removeFromCartHandler(item._id, item.size)}>
                                                    <FaTrashAlt />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    )}
                </Col>
                <Col md={6} lg={4}>
                    <ListGroup variant="flush" className="border-0">
                        <ListGroup.Item className="d-md-flex justify-content-between align-items-center">
                            <div>
                                <h3 className="mb-0">
                                    Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)}) items
                                </h3>
                            </div>
                            <div className="mt-2 mt-md-0">
                                £{cartItems.reduce((acc, item) => {
                                const discountedPrice = calculateDiscountedPrice(item.price, item.discount ?? 0);
                                return acc + item.qty * discountedPrice;
                            }, 0).toFixed(2)}
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Button
                                        type='button'
                                        variant='info'
                                        className='btn-block orange-outline'
                                        disabled={cartItems.length === 0}
                                        onClick={() => navigate(-2)}
                                        style={{ width: '100%' }} // Ensure button takes full width of the column on all screens
                                    >
                                        Continue Shopping
                                    </Button>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Button
                                        type='button'
                                        variant='primary'
                                        className='btn-block blue-outline'
                                        disabled={cartItems.length === 0}
                                        onClick={checkoutHandler}
                                        style={{ width: '100%' }} // Ensure button takes full width of the column on all screens
                                    >
                                        Go To Checkout
                                    </Button>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
        </>
    );
};

export default CartScreen;