import { useNavigate, Link } from "react-router-dom";
import StayTop from "../components/StayTop";
import {useEffect} from "react";

const About = () => {
    const navigate = useNavigate();
    return (
     <>
         <StayTop />
          <Link className="btn btn-light my-3 orange-outline" onClick={() => navigate(-1)}>
                Go Back
          </Link>
            <div>
                <p></p>
                <h3>Don't worry...   the privacy page is coming soon!!</h3>

            </div>
    </>
    );
};
export default About;