import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useResendVerificationEmailMutation } from '../slices/usersApiSlice';
import { toast } from 'react-toastify';
import FormContainer from '../components/FormContainer';
import Loader from '../components/Loader';

const ResendVerificationScreen = () => {
    const [email, setEmail] = useState('');
    const [resendVerificationEmail, { isLoading }] = useResendVerificationEmailMutation();

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            await resendVerificationEmail({ email }).unwrap();
            toast.success('Verification email sent');
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    return (
        <FormContainer>
            <h1>Resend Verification Email</h1>
            <Form onSubmit={submitHandler}>
                <Form.Group controlId="email">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                </Form.Group>
                <Button type="submit" variant="primary" className="mt-3" disabled={isLoading}>
                    Resend Email
                </Button>
                {isLoading && <Loader />}
            </Form>
        </FormContainer>
    );
};

export default ResendVerificationScreen;