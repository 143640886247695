import React from 'react';
import { Pagination } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleLeft, faCircleRight, faFastBackward, faFastForward } from '@fortawesome/free-solid-svg-icons';
import { faArrowRotateLeft, faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';

const Paginate = ({ pages, page, isAdmin = false, keyword = '' }) => {
    const navigate = useNavigate();

    const navigateTo = (page) => {
        const path = !isAdmin ? (keyword ? `/search/${keyword}/page/${page}` : `/page/${page}`) : `/admin/productlist/${page}`;
        navigate(path);
    };

    const pageNumbers = () => {
        const maxPagesToShow = 3; // Adjust this to change how many pages are shown around the current page
        let items = [];
        let leftSide = page - 2;
        let rightSide = page + 2;

        if (leftSide < 1) {
            leftSide = 1;
            rightSide = maxPagesToShow;
        }

        if (rightSide > pages) {
            leftSide = pages - maxPagesToShow + 1;
            rightSide = pages;
        }

        for (let number = leftSide; number <= rightSide; number++) {
            if (number > 0 && number <= pages) {
                items.push(
                    <LinkContainer
                        key={number}
                        to={!isAdmin ? (keyword ? `/search/${keyword}/page/${number}` : `/page/${number}`) : `/admin/productlist/page/${number}`}>
                        <Pagination.Item active={number === page}>
                            {number}
                        </Pagination.Item>
                    </LinkContainer>
                );
            }
        }

        // Ensure the last page is always visible if not already included
        if (rightSide < pages) {
            items.push(<Pagination.Ellipsis key="ellipsEnd" />);
            items.push(
                <LinkContainer
                    key={pages}
                    to={!isAdmin ? (keyword ? `/search/${keyword}/page/${pages}` : `/page/${pages}`) : `/admin/productlist/page/${pages}`}>
                    <Pagination.Item active={pages === page}>
                        {pages}
                    </Pagination.Item>
                </LinkContainer>
            );
        }

        return items;
    };

    return (
        pages > 1 && (
            <Pagination style={{ display: 'flex', justifyContent: 'center' }} className="pagination-container">
                <Pagination.First onClick={() => navigateTo(1)} disabled={page === 1}>
                    {/*<FontAwesomeIcon icon={faArrowRotateLeft}  className="pagination-arrows" />*/}
                    <FontAwesomeIcon icon={faFastForward}  className="pagination-arrows" style={{ transform: 'scaleX(-1)' }} />
                </Pagination.First>
                <Pagination.Prev onClick={() => navigateTo(page - 1)} disabled={page === 1}>
                    <FontAwesomeIcon icon={faCircleLeft} className="pagination-arrows" />
                </Pagination.Prev>
                {pageNumbers()}
                <Pagination.Next onClick={() => navigateTo(page + 1)} disabled={page === pages}>
                    <FontAwesomeIcon icon={faCircleRight} className="pagination-arrows" />
                </Pagination.Next>
                <Pagination.Last onClick={() => navigateTo(pages)} disabled={page === pages}>
                    <FontAwesomeIcon icon={faFastForward} className="pagination-arrows" />
                    {/*<FontAwesomeIcon icon={faArrowRotateRight} className="pagination-arrows" />*/}
                </Pagination.Last>
            </Pagination>
        )
    );
};

export default Paginate;
