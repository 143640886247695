import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import HeaderNav from '../components/HeaderNav';
import ImageLayout from '../components/ImageLayout';
import Products from '../components/Products';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate';
import Meta from '../components/Meta';
import StayTop from '../components/StayTop';
import ProductsCarousel from '../components/ProductsCarousel';
import { useGetProductsQuery } from '../slices/productsApiSlice';

const HomeScreen = () => {
    const { pageNumber, keyword } = useParams();
    const [description, setDescription] = useState('');
    const [descriptionType, setDescriptionType] = useState('');
    const { data, isLoading, error } = useGetProductsQuery({ keyword, pageNumber });

    // Function to update the description from HeaderNav
    const updateProductDescription = (newDescription) => {
        setDescription(newDescription);
        setDescriptionType('product');
    };

    // Function to update the description from ImageLayout
    const updateKeywordDescription = (newDescription) => {
        setDescription(newDescription);
        setDescriptionType('keyword');
    };

    // Scroll to top when pageNumber changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pageNumber]);

    return (
        <>
            <HeaderNav onProductSelect={updateProductDescription} />
            {isLoading ? (
                <Loader />
            ) : error ? (
                <Message variant='danger'>{error?.data?.message || error.error}</Message>
            ) : (
                <>
                    <ImageLayout onIconClick={updateKeywordDescription} />
                    <Meta />

                    {/* Conditionally render the description based on the type */}
                    {descriptionType === 'keyword' && (
                        <h3 className='d-flex justify-content-center mb-4 mt-4 description-style sexy-text'>{description}</h3>
                    )}

                    {descriptionType === 'product' && (
                        <h3 className='d-flex justify-content-center mb-4 mt-4 description-style sexy-text2'>{description}</h3>

                    )}
                    {/*<ProductsCarousel />*/}
                    <Row id="targetRow">
                        {data.products.map((product) => (
                            <Col key={product._id} xs={12} sm={6} md={6} lg={3} xl={3}>
                                <Products product={product} showDiscount={product.isClearance} />
                            </Col>
                        ))}
                    </Row>
                    <Paginate pages={data.pages} page={data.page} keyword={keyword ? keyword : ''}></Paginate>
                </>
            )}
            <StayTop />
        </>
    );
};

export default HomeScreen;