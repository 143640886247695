// utils/discountUtils.js

export const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
};

export const calculateDiscountedPrice = (price, discount) => {
    return price - (price * discount / 100);
};

export const calculateTotalPrice = (itemsPrice, shippingPrice, taxPrice) => {
    return (Number(itemsPrice) + Number(shippingPrice) + Number(taxPrice)).toFixed(2);
};

export const updateCart = (state) => {
    state.itemsPrice = addDecimals(state.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0));
    state.shippingPrice = addDecimals(state.itemsPrice > 100 ? 0 : 3.99);
    state.taxPrice = addDecimals(Number((0.20 * state.itemsPrice).toFixed(2)));
    state.totalPrice = (
        Number(state.itemsPrice) +
        Number(state.shippingPrice) +
        Number(state.taxPrice)
    ).toFixed(2);
    localStorage.setItem('cart', JSON.stringify(state));
    return state;
};

export const getDiscountDisplayPercentage = (price, discount) => {
    if (discount > 0) {
        const discountPercentage = ((price - calculateDiscountedPrice(price, discount)) / price) * 100;
        return `${discountPercentage.toFixed(0)}%`;
    }
    return null;
};
