import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap'; // Import Row and Col
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import FormContainer from '../../components/FormContainer';
import { toast } from 'react-toastify';
import {
    useGetProductDetailsQuery,
    useUpdateProductMutation,
    useUploadProductImageMutation,
} from '../../slices/productsApiSlice';

const ProductEditScreen = () => {
    const { id: productId } = useParams();
    const [productData, setProductData] = useState({
        productNumber: 0,
        name: '',
        image: '',
        imageArray: [],
        description: '',
        colour: [],
        brand: '',
        genre: '',
        category: '',
        productType: '',
        productTypeDescription: '',
        sizeArray: [],
        searchingStyle: '',
        character: '',
        price: 0,
        countInStock: 0,
        rating: 0,
        numReviews: 0,
        carouselImage: false,
        font: '',
        fontImage: false,
        fontOrder: 0,
        fontCategory: '',
    });

    const { data: product, isLoading, refetch, error } = useGetProductDetailsQuery(productId);

    const [updateProduct, { isLoading: loadingUpdate }] = useUpdateProductMutation();
    const [uploadProductImage, { isLoading: loadingUpload }] = useUploadProductImageMutation();

    const navigate = useNavigate();

    useEffect(() => {
        if (product) {
            setProductData({
                productNumber: product.productNumber,
                name: product.name,
                image: product.image,
                imageArray: product.imageArray,
                description: product.description,
                colour: product.colour,
                brand: product.brand,
                genre: product.genre,
                category: product.category,
                productType: product.productType,
                productTypeDescription: product.productTypeDescription,
                sizeArray: product.sizeArray,
                searchingStyle: product.searchingStyle,
                character: product.character,
                price: product.price,
                countInStock: product.countInStock,
                rating: product.rating,
                numReviews: product.numReviews,
                carouselImage: product.carouselImage,
                font: product.font,
                fontImage: product.fontImage,
                fontOrder: product.fontOrder,
                fontCategory: product.fontCategory,
            });
        }
    }, [product]);

    const submitHandler = async (e) => {
        e.preventDefault();
        const updatedProduct = {
            productId,
            ...productData,
        };

        try {
            const result = await updateProduct(updatedProduct).unwrap();
            toast.success('Product updated successfully');
            refetch();
            navigate('/admin/productlist');
        } catch (error) {
            toast.error(error?.data?.message || error.error);
        }
    };

    const uploadFileHandler = async (e) => {
        const formData = new FormData();
        formData.append('image', e.target.files[0]);
        try {
            const res = await uploadProductImage(formData).unwrap();
            toast.success('Image uploaded successfully');
            setProductData((prevData) => ({ ...prevData, image: res.image }));
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProductData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleArrayChange = (e, index, arrayName) => {
        const { value } = e.target;
        const newArray = [...productData[arrayName]];
        newArray[index] = value;
        setProductData((prevData) => ({ ...prevData, [arrayName]: newArray }));
    };

    const addImageToArray = () => {
        setProductData((prevData) => ({ ...prevData, imageArray: [...prevData.imageArray, ''] }));
    };

    const removeImageFromArray = (index) => {
        const newArray = [...productData.imageArray];
        newArray.splice(index, 1);
        setProductData((prevData) => ({ ...prevData, imageArray: newArray }));
    };

    const addSizeToArray = () => {
        setProductData((prevData) => ({
            ...prevData,
            sizeArray: [...prevData.sizeArray, { size: '', tshirtInStock: 0 }],
        }));
    };

    const removeSizeFromArray = (index) => {
        const newArray = [...productData.sizeArray];
        newArray.splice(index, 1);
        setProductData((prevData) => ({ ...prevData, sizeArray: newArray }));
    };

    const handleSizeChange = (e, index, field) => {
        const { value } = e.target;
        const newSizeArray = [...productData.sizeArray];
        newSizeArray[index][field] = value;
        setProductData((prevData) => ({ ...prevData, sizeArray: newSizeArray }));
    };

    return (
        <>
            <Link to='/admin/productlist' className='btn btn-light my-3'>
                Go Back
            </Link>
            <FormContainer>
                <h1>Edit Product</h1>
                {loadingUpdate && <Loader />}
                {isLoading ? (
                    <Loader />
                ) : error ? (
                    <Message variant='danger'>{error.data.message}</Message>
                ) : (
                    <Form onSubmit={submitHandler}>
                        {/* Wrap fields in Row and Col for layout control */}
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId='productNumber' className='my-2'>
                                    <Form.Label>Product Number</Form.Label>
                                    <Form.Control
                                        type='number'
                                        placeholder='Enter product number'
                                        name='productNumber'
                                        value={productData.productNumber}
                                        onChange={handleInputChange}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId='name' className='my-2'>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Enter name'
                                        name='name'
                                        value={productData.name}
                                        onChange={handleInputChange}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Form.Group controlId='price' className='my-2'>
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control
                                        type='number'
                                        placeholder='Enter price'
                                        name='price'
                                        value={productData.price}
                                        onChange={handleInputChange}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId='countInStock' className='my-2'>
                                    <Form.Label>Count In Stock</Form.Label>
                                    <Form.Control
                                        type='number'
                                        placeholder='Enter count in stock'
                                        name='countInStock'
                                        value={productData.countInStock}
                                        onChange={handleInputChange}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group controlId='image' className='my-2'>
                            <Form.Label>Image</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Enter image URL'
                                name='image'
                                value={productData.image}
                                onChange={handleInputChange}
                            ></Form.Control>
                            <Form.Control type='file' label='Choose File' onChange={uploadFileHandler}></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='imageArray' className='my-2'>
                            <Form.Label>Image Array</Form.Label>
                            {productData.imageArray.map((image, index) => (
                                <div key={index} className="mb-2">
                                    <Form.Control
                                        type='text'
                                        placeholder='Enter image URL'
                                        value={image}
                                        onChange={(e) => handleArrayChange(e, index, 'imageArray')}
                                    ></Form.Control>
                                    <Button variant='danger' onClick={() => removeImageFromArray(index)} className="mt-2">
                                        Remove
                                    </Button>
                                </div>
                            ))}
                            <Button onClick={addImageToArray} className="mt-2">Add Image</Button>
                        </Form.Group>

                        <Form.Group controlId='description' className='my-2'>
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as='textarea'
                                placeholder='Enter description'
                                name='description'
                                value={productData.description}
                                onChange={handleInputChange}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='colour' className='my-2'>
                            <Form.Label>Colour</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Enter colours (comma separated)'
                                name='colour'
                                value={productData.colour.join(', ')}
                                onChange={(e) => setProductData({ ...productData, colour: e.target.value.split(', ') })}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='brand' className='my-2'>
                            <Form.Label>Brand</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Enter brand'
                                name='brand'
                                value={productData.brand}
                                onChange={handleInputChange}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='genre' className='my-2'>
                            <Form.Label>Genre</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Enter genre'
                                name='genre'
                                value={productData.genre}
                                onChange={handleInputChange}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='category' className='my-2'>
                            <Form.Label>Category</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Enter category'
                                name='category'
                                value={productData.category}
                                onChange={handleInputChange}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='productType' className='my-2'>
                            <Form.Label>Product Type</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Enter product type'
                                name='productType'
                                value={productData.productType}
                                onChange={handleInputChange}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='productTypeDescription' className='my-2'>
                            <Form.Label>Product Type Description</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Enter product type description'
                                name='productTypeDescription'
                                value={productData.productTypeDescription}
                                onChange={handleInputChange}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='searchingStyle' className='my-2'>
                            <Form.Label>Searching Style</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Enter searching style'
                                name='searchingStyle'
                                value={productData.searchingStyle}
                                onChange={handleInputChange}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='character' className='my-2'>
                            <Form.Label>Character</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Enter character'
                                name='character'
                                value={productData.character}
                                onChange={handleInputChange}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='rating' className='my-2'>
                            <Form.Label>Rating</Form.Label>
                            <Form.Control
                                type='number'
                                placeholder='Enter rating'
                                name='rating'
                                value={productData.rating}
                                onChange={handleInputChange}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='numReviews' className='my-2'>
                            <Form.Label>Number of Reviews</Form.Label>
                            <Form.Control
                                type='number'
                                placeholder='Enter number of reviews'
                                name='numReviews'
                                value={productData.numReviews}
                                onChange={handleInputChange}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='carouselImage' className='my-2'>
                            <Form.Check
                                type='checkbox'
                                label='Carousel Image'
                                name='carouselImage'
                                checked={productData.carouselImage}
                                onChange={(e) => setProductData({ ...productData, carouselImage: e.target.checked })}
                            ></Form.Check>
                        </Form.Group>

                        <Form.Group controlId='font' className='my-2'>
                            <Form.Label>Font</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Enter font'
                                name='font'
                                value={productData.font}
                                onChange={handleInputChange}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='fontImage' className='my-2'>
                            <Form.Check
                                type='checkbox'
                                label='Font Image'
                                name='fontImage'
                                checked={productData.fontImage}
                                onChange={(e) => setProductData({ ...productData, fontImage: e.target.checked })}
                            ></Form.Check>
                        </Form.Group>

                        <Form.Group controlId='fontOrder' className='my-2'>
                            <Form.Label>Font Order</Form.Label>
                            <Form.Control
                                type='number'
                                placeholder='Enter font order'
                                name='fontOrder'
                                value={productData.fontOrder}
                                onChange={handleInputChange}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='fontCategory' className='my-2'>
                            <Form.Label>Font Category</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Enter font category'
                                name='fontCategory'
                                value={productData.fontCategory}
                                onChange={handleInputChange}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='sizeArray' className='my-2'>
                            <Form.Label>Size Array</Form.Label>
                            {productData.sizeArray.map((size, index) => (
                                <div key={index} className="mb-2">
                                    <Form.Control
                                        type='text'
                                        placeholder='Enter size'
                                        value={size.size}
                                        onChange={(e) => handleSizeChange(e, index, 'size')}
                                        className="mb-1"
                                    ></Form.Control>
                                    <Form.Control
                                        type='number'
                                        placeholder='Enter count in stock'
                                        value={size.tshirtInStock}
                                        onChange={(e) => handleSizeChange(e, index, 'tshirtInStock')}
                                    ></Form.Control>
                                    <Button variant='danger' onClick={() => removeSizeFromArray(index)} className="mt-2">
                                        Remove
                                    </Button>
                                </div>
                            ))}
                            <Button onClick={addSizeToArray} className="mt-2">Add Size</Button>
                        </Form.Group>

                        <Button type='submit' variant='primary' className='my-2'>
                            Update
                        </Button>
                    </Form>
                )}
            </FormContainer>
        </>
    );
};

export default ProductEditScreen;