import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Row, Col, ListGroup, Image, Card, Form } from "react-bootstrap"; // Import Form
import CheckoutSteps from "../components/CheckoutSteps";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { clearCartItems } from '../slices/cartSlice';
import Message from "../components/Message";
import { useCreateOrderMutation } from '../slices/ordersApiSlice';
import { calculateDiscountedPrice, addDecimals } from "../utils/discountUtils";

const PlaceOrderScreen = () => {
    const navigate = useNavigate();
    const cart = useSelector(state => state.cart);
    const dispatch = useDispatch();
    const [promoCode, setPromoCode] = useState(''); // State for promo code
    const [isFreeShipping, setIsFreeShipping] = useState(false); // State to track if shipping is free

    const [createOrder, { isLoading, error }] = useCreateOrderMutation();

    const validFreeShipPromoCode = process.env.REACT_APP_FREESHIP_PROMO_CODE;


    const handleApplyPromoCode = () => {
        if (promoCode === validFreeShipPromoCode) {
            setIsFreeShipping(true);
            toast.success("Promo code successful! Free shipping has been applied.");
        } else {
            setIsFreeShipping(false);
            toast.error("Invalid promo code.");
        }
    };

    // Calculate prices
    const calculateItemsPrice = () => {
        return addDecimals(
            cart.cartItems.reduce((acc, item) => {
                const discountedPrice = calculateDiscountedPrice(item.price, item.discount ?? 0);
                return acc + item.qty * discountedPrice;
            }, 0)
        );
    };

    const itemsPrice = calculateItemsPrice();
    const shippingPrice = isFreeShipping ? 0 : addDecimals(itemsPrice > 100 ? 0 : 3.99); // Free shipping if promo code applied
    const taxPrice = addDecimals(Number((0.20 * itemsPrice).toFixed(2)));
    const totalPrice = (Number(itemsPrice) + Number(shippingPrice)).toFixed(2);

    useEffect(() => {
        if (!cart.shippingAddress.address) {
            navigate('/shipping');
        } else if (!cart.paymentMethod) {
            navigate('/payment');
        }
    }, [cart.paymentMethod, cart.shippingAddress, navigate]);

    const placeOrderHandler = async () => {
        try {
            const orderData = {
                orderItems: cart.cartItems.map(item => ({
                    name: item.name,
                    qty: item.qty,
                    image: item.image,
                    price: item.price,
                    product: item._id // Ensure this is the product ID
                })),
                shippingAddress: cart.shippingAddress,
                paymentMethod: cart.paymentMethod,
                itemsPrice: Number(itemsPrice),
                shippingPrice: Number(shippingPrice),
                taxPrice: Number(taxPrice),
                totalPrice: Number(totalPrice)
            };
            console.log('Order data:', JSON.stringify(orderData, null, 2)); // Debugging logs

            const res = await createOrder(orderData).unwrap();
            dispatch(clearCartItems());
            navigate(`/order/${res._id}`);
        } catch (err) {
            toast.error("Failure to process your Order: " + err.message);
        }
    };

    return (
        <>
            <Link className="btn btn-light my-3 orange-outline" onClick={() => navigate(-1)}>
                Go Back
            </Link>
            <Row>
                <Col md={8} className="mt-3">
                    <ListGroup variant='flush'>
                        <ListGroup.Item>
                            <h2>Shipping</h2>
                            <p>
                                <strong>Address: </strong>
                                {cart.shippingAddress.address}, {cart.shippingAddress.city}{' '}
                                {cart.shippingAddress.postalCode},{' '}
                                {cart.shippingAddress.country}
                            </p>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h2>Payment Method</h2>
                            <p>
                                <strong>Method: </strong>
                                {cart.paymentMethod}
                            </p>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h2>Order Items</h2>
                            {cart.cartItems.length === 0 ? (
                                <Message>Your cart is empty</Message>
                            ) : (
                                <ListGroup variant='flush'>
                                    {cart.cartItems.map((item, index) => {
                                        const discountedPrice = calculateDiscountedPrice(item.price, item.discount ?? 0);
                                        return (
                                            <ListGroup.Item key={index}>
                                                <Row>
                                                    <Col md={3}>
                                                        <Image
                                                            src={item.image}
                                                            alt={item.name}
                                                            fluid rounded />
                                                    </Col>
                                                    <Col md={2}>
                                                        Qty {item.qty}
                                                    </Col>
                                                    <Col md={2}>
                                                        {item.isClearance ? (
                                                            <>
                                                                <span style={{ textDecoration: 'line-through' }}>£{item.qty * item.price.toFixed(2)}</span>
                                                                <br />
                                                                <span>£{item.qty * discountedPrice.toFixed(2)}</span>
                                                            </>
                                                        ) : (
                                                            <span>£{item.price.toFixed(2)}</span>
                                                        )}
                                                    </Col>
                                                    <Col md={4} className="ellipsis" >
                                                        {item.name}
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        );
                                    })}
                                </ListGroup>
                            )}
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col md={8} lg={4} className="mt-5">
                    <Card>
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <h2>Order Summary</h2>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col>Items: ({cart.cartItems.reduce((acc, item) => acc + item.qty, 0)})</Col>
                                    <Col>£{itemsPrice}</Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col>Delivery: </Col>
                                    <Col>£{shippingPrice}</Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col>VAT (Inc.): </Col>
                                    <Col>£{taxPrice}</Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col>Total: </Col>
                                    <Col>£{totalPrice}</Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Form>
                                    <Form.Group controlId="promoCode">
                                        <Form.Label>Promo Code:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter promo code"
                                            value={promoCode}
                                            onChange={(e) => setPromoCode(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Button
                                        type='button'
                                        className='btn btn-primary btn-block my-2 blue-outline'
                                        onClick={handleApplyPromoCode}
                                        style={{ width: '100%' }}
                                    >
                                        Apply Promo Code
                                    </Button>
                                </Form>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                {error && <Message variant='danger'>{error.data.message}</Message>}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row className="align-items-center">
                                    <Col>
                                        <Button
                                            type='button'
                                            className='btn btn-warning btn-block my-2 orange-outline'
                                            disabled={cart.cartItems.length === 0}
                                            onClick={() => navigate('/cart')}
                                            style={{ width: '100%' }}
                                        >
                                            Edit Order
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            type='button'
                                            className='btn btn-primary btn-block my-2 blue-outline'
                                            disabled={cart.cartItems.length === 0}
                                            onClick={placeOrderHandler}
                                            style={{ width: '100%' }}
                                        >
                                            Place Order
                                        </Button>
                                    </Col>
                                </Row>
                                {isLoading && <Loader />}
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default PlaceOrderScreen;
