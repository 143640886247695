import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { useRegisterMutation } from "../slices/usersApiSlice";
import { toast } from "react-toastify";
import StayTop from "../components/StayTop";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const RegisterScreen = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordMatchError, setPasswordMatchError] = useState('');
    const [passwordErrors, setPasswordErrors] = useState([]);
    const [message, setMessage] = useState('');

    const navigate = useNavigate();
    const [register, { isLoading }] = useRegisterMutation();
    const { userInfo } = useSelector((state) => state.auth);
    const { search } = useLocation();
    const sp = new URLSearchParams(search);
    const redirect = sp.get('redirect') || "/";

    useEffect(() => {
        if (userInfo) {
            navigate(redirect);
        }
    }, [userInfo, redirect, navigate]);

    const submitHandler = async (e) => {
        e.preventDefault();

        if (!agreeTerms) {
            toast.error("You must agree to the terms and conditions");
            return;
        }

        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }

        const passwordErrors = validatePasswordComplexity(password);
        if (passwordErrors.length > 0) {
            passwordErrors.forEach((error) => toast.error(error));
            return;
        }

        try {
            await register({ name, email, phone, password, agreeTerms, date: new Date() }).unwrap();
            setMessage('Registration successful. Please check your email to verify your account.');
            setTimeout(() => {
                navigate('/login');
            }, 5000); // Redirect to login page after 5 seconds
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    const validateEmailFormat = (email) => {
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return regex.test(email);
    };

    const validatePasswordComplexity = (password) => {
        const errors = [];
        if (!/[a-z]/.test(password)) {
            errors.push("At least one lowercase letter");
        }
        if (!/[A-Z]/.test(password)) {
            errors.push("At least one uppercase letter");
        }
        if (!/\d/.test(password)) {
            errors.push("At least one digit");
        }
        if (!/[!@#$%^&*]/.test(password)) {
            errors.push("At least one special character (!@#$%^&*)");
        }
        if (password.length < 8) {
            errors.push("Minimum length of 8 characters");
        }
        return errors;
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        const errors = validatePasswordComplexity(newPassword);
        setPasswordErrors(errors);
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
        setPasswordMatchError(password !== newConfirmPassword ? "Passwords do not match." : "");
    };

    return (
        <FormContainer>
            <h1>Sign Up</h1>
            {message && <div className="alert alert-success">{message}</div>}
            <Form onSubmit={submitHandler}>
                <Form.Group controlId="name" className="my-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    ></Form.Control>
                </Form.Group>

                <Form.Group controlId="email" className="my-3">
                    <Form.Label>Email Address (verification required)</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter email"
                        autoComplete="off"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setIsEmailValid(validateEmailFormat(e.target.value));
                        }}
                    ></Form.Control>
                    {!isEmailValid && (
                        <div className="text-danger">Please enter a valid email address.</div>
                    )}
                </Form.Group>

                <Form.Group controlId="phone" className="my-3">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter phone number (optional)"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    ></Form.Control>
                </Form.Group>

                <Form.Group controlId="password" className="my-3">
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter password"
                            autoComplete="off"
                            value={password}
                            onChange={handlePasswordChange}
                        />
                        <Button
                            variant="outline-secondary"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </Button>
                    </InputGroup>
                    {passwordErrors.length > 0 && (
                        <ul className="text-danger">
                            {passwordErrors.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    )}
                </Form.Group>

                <Form.Group controlId="confirmPassword" className="my-3">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Confirm password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                        />
                        <Button
                            variant="outline-secondary"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </Button>
                    </InputGroup>
                    {passwordMatchError && (
                        <div className="text-danger">{passwordMatchError}</div>
                    )}
                </Form.Group>

                <Form.Group controlId="terms" className="my-3">
                    <Form.Check
                        type="checkbox"
                        checked={agreeTerms}
                        onChange={(e) => setAgreeTerms(e.target.checked)}
                    />
                    <Form.Label>
                        I agree to the{' '}
                        <Link to="/terms" target="_blank" rel="noopener noreferrer">
                            terms and conditions
                        </Link>
                    </Form.Label>
                </Form.Group>

                {isLoading && <Loader />}
                <Button
                    type="submit"
                    variant="primary"
                    className="mt-3"
                    disabled={
                        isLoading ||
                        passwordErrors.length > 0 ||
                        passwordMatchError ||
                        !isEmailValid ||
                        !email ||
                        !password ||
                        !confirmPassword ||
                        !agreeTerms
                    }
                >
                    Register
                </Button>
            </Form>
            <Row className="py-3">
                <Col>
                    Already registered?{" "}
                    <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
                        Login
                    </Link>
                </Col>
            </Row>
            <StayTop />
        </FormContainer>
    );
};

export default RegisterScreen;