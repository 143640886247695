export const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
}

export const updateCart = (state) => {
    //calculate items price
    state.itemsPrice = addDecimals(state.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0));

    //calculate shipping price - if order is over £100 = free, else £3.99
    state.shippingPrice = addDecimals(state.itemsPrice > 100 ? 0 : 3.99);
    //calculate tax price at uk 20% to 2 units
    state.taxPrice = addDecimals(Number((0.20 * state.itemsPrice).toFixed(2)));
    //calculate total price
    state.totalPrice = (
        Number(state.itemsPrice) +
        Number(state.shippingPrice) +
        Number((state.taxPrice) - (state.taxPrice))
    ).toFixed(2);

    localStorage.setItem('cart', JSON.stringify(state));
    return state;
}