import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FormContainer from '../components/FormContainer';
import Loader from '../components/Loader';
import { useResetPasswordMutation } from '../slices/usersApiSlice';

const PasswordResetScreen = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [resetPassword] = useResetPasswordMutation();

    const submitHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true);  // Start loading indicator
        try {
            await resetPassword({ email }).unwrap(); // Attempt to reset the password
            toast.success('Check your email for password reset instructions.');
            setEmail('');  // Clear the email field after successful submission
        } catch (err) {
            toast.error(err?.data?.message || "Failed to send password reset email."); // Display error message
        } finally {
            setIsLoading(false);  // Stop loading indicator regardless of outcome
        }
    };

    return (
        <FormContainer>
            <h3>Reset Password</h3>
            <Form onSubmit={submitHandler}>
                <Form.Group controlId="email" className="mb-3"> {/* Added bottom margin */}
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="mb-2"  // Added margin below the input field for spacing
                    />
                </Form.Group>

                <Button type="submit"
                        className="mt-2 blue-outline"> {/* Full width and margin */}
                    Send Reset Link
                </Button>


                {isLoading && <Loader />}
            </Form>
            <Row className="py-3">
                <Col>
                    Remembered your password? <Link to="/login">Login</Link>
                </Col>
            </Row>
        </FormContainer>
    );
};

export default PasswordResetScreen;