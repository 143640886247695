import {useNavigate, useParams} from "react-router-dom";
import {useState} from "react";
import {Button, Form} from "react-bootstrap";
import {FaSearch} from "react-icons/fa";


const SearchBox = () => {
    const submitHandler = (e) => {
        e.preventDefault();
        if (keyword.trim()) {
            setKeyword('');
            navigate(`/search/${keyword}`);
        } else {
            navigate('/');
        }
    }
    const navigate = useNavigate();
    const { keyword:urlKeyword } = useParams();
    const [keyword, setKeyword] = useState(urlKeyword || '');
    return (



            <Form onSubmit={submitHandler} className='d-flex ml-auto'>
                <Form.Control
                    id='nav-dropdown'
                    className="search-input mr-sm-5 ml-sm-5 navbar-content-sm-left"
                    type='text'
                    name='q'
                    onChange={(e) => setKeyword(e.target.value)}
                    placeholder='Search Products...'
                    value={keyword}
                ></Form.Control>
                {/*search icon colour below FD7014*/}
                <Button style={{color: "#000000"}} type='submit' variant='outline-clear' className='p-2 mx-2'>
                    <FaSearch />
                </Button>
            </Form>


    );
};

export default SearchBox;