import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ProductsCarousel from './ProductsCarousel';

const ProductCard = ({ product }) => {
    return (
        <Card className='product-cardCarousel'>
            <Link >
                <ProductsCarousel />
            </Link>
        </Card>
    );
};

export default ProductCard;