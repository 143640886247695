import Header from "./components/Header";
import {Container} from "react-bootstrap";
import Footer from "./components/Footer";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const App = () =>{
  return (
      <>
            <Header />
                <main className='main-content'>
                <Container>
                <Outlet />
                </Container>
                </main>
            <Footer />
          <ToastContainer />
      </>
  )
}

export default App