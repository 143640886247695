import React from 'react';
import { Link } from 'react-router-dom';
import { Carousel, Image } from 'react-bootstrap';
import Loader from "./Loader";
import Message from "./Message";
import { useGetTopProductsQuery } from "../slices/productsApiSlice";

const ProductsCarousel = () => {
    // Fetch top products data using the custom hook
    const { data: products, isLoading, error } = useGetTopProductsQuery();

    // Display loader while data is being fetched
    if (isLoading) return <Loader />;

    // Display error message if there is an error in fetching data
    if (error) return <Message variant='danger'>{error}</Message>;

    return (
        <Carousel
            pause='hover'  // Pause the carousel on hover
            showThumbs={false}  // Do not show thumbnail indicators
            className='bg-opacity-100 py-2 mb-4 custom-carousel-indicators'  // Apply custom classes for styling
        >
            {/* Iterate over the products to create carousel items */}
            {products?.map(product => (
                <Carousel.Item key={product._id}> {/*Use product ID as the key for each item*/}
                    <Link to={`/search/${product.productType}`}>
                        {/*// Link to the product type search page*/}
                        <Image
                            src={product.carousel}  // Image source for the product
                            alt={product.brand}  // Alt text for the image
                            fluid  // Make the image responsive
                        />
                    </Link>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default ProductsCarousel;
