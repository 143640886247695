import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faXTwitter, faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import insta from '../assets/Instagram_Glyph_Gradient.png';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const Instag = {
        src: insta,
        alt: 'Marvel',
    };
    return (
        // <footer style={{ backgroundColor: "#3E6D9C" }} className="text-light py-2">
            <footer style={{ backgroundColor: 'white', position: 'relative' }} className="text-light py-2">

            <Container>
                <Row>
                    <Col md={3} className="mb-1">
                        <h5 className="mb-3 custom-margin" style={{ color: 'black' }}>Company</h5>
                        <ul className="list-unstyled">
                            <li className="footer-list-item">
                                <a href="/about" className="text-dark">About Us</a>
                            </li>
                            <li className="footer-list-item">
                                <a href="/terms" className="text-dark">Terms & Conditions</a>
                            </li>
                            <li className="footer-list-item">
                                <a href="/privacy" className="text-dark">Privacy Policy</a>
                            </li>
                            <li className="footer-list-item">
                                <a href="/cookie" className="text-dark">Cookie Policy</a>
                            </li>
                        </ul>
                    </Col>
                    <Col md={3} className="mb-1">
                        <h5 className="mb-3 custom-margin" >Help & Support</h5>
                        <ul className="list-unstyled">
                            <li className="footer-list-item">
                                <a href="/faqs" className="text-dark">FAQs</a>
                            </li>
                            <li className="footer-list-item">
                                <a href="/contact" className="text-dark">Contact Us</a>
                            </li>
                            <li className="footer-list-item">
                                <a href="/accessibility" className="text-dark">Accessibility</a>
                            </li>
                        </ul>
                    </Col>
                    <Col md={3} className="mb-1">
                        <h5 className="mb-3 custom-margin" style={{ color: 'black' }}>Follow Us</h5>
                        <div className="d-flex">
                            <a href="https://www.instagram.com/retrodayz_/" target="_blank" rel="noopener noreferrer" className="me-3 text-light">
                                <img img {...Instag} className="footer-social-icon" style={{ width: '36px', height: '36px' }} />
                            </a>
                            <a href="https://twitter.com/retrodayz_" target="_blank" rel="noopener noreferrer" className="me-3 text-light">
                                <FontAwesomeIcon icon={faXTwitter} className="footer-social-icon" style={{ fontSize: '36px', color: 'black' }} />
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=61558538470404" target="_blank" rel="noopener noreferrer" className="text-light">
                                <FontAwesomeIcon icon={faFacebookSquare} className="footer-social-icon" style={{ fontSize: '36px' }} />
                            </a>
                        </div>
                    </Col>
                    <Col md={3} className="mb-1">
                        <h5 className="mb-3 custom-margin" style={{ color: 'black' }}>&copy; {currentYear} Retrodayz</h5>
                        <div className="d-flex">
                            <section className="trustbox-section">
                                <Container>
                                    <div
                                        className="trustpilot-widget"
                                        data-locale="en-GB"
                                        data-template-id="56278e9abfbbba0bdcd568bc"
                                        data-businessunit-id="66e93875c3677f7723181e7d"
                                        data-style-height="40px"
                                        data-style-width="100%"
                                    >
                                        <a href="https://uk.trustpilot.com/review/retrodayz.co.uk" target="_blank" rel="noopener">
                                            Trustpilot
                                        </a>
                                    </div>
                                </Container>
                            </section>
                        </div>
                        {/*Copyright 2024 © Retrodayz Ltd. Retrodayz Ltd is registered in England & Wales, company number 09739902, VAT Number GB 227 324 914. Retrodayz and the Retrodayz logo are registered trademarks of Retrodayz ltd. All rights reserved.*/}
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
