import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Form, Button, Container, ListGroup } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useGetProductDetailsQuery, useCreateReviewMutation } from "../slices/productsApiSlice";
import { addToCart } from "../slices/cartSlice";
import ProductsItemCarousel from "../components/ProductItemCarousel";
import { toast } from "react-toastify";
import Meta from "../components/Meta";
import StayTop from "../components/StayTop";
import ProductDetails from "../components/ProductDetails";
import Rating from "../components/Rating"; // Make sure you have a Rating component

const ProductScreen = () => {
    const { id: productId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [qty, setQty] = useState(1);
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const [selectedSize, setSelectedSize] = useState('');
    const [availableStockForSize, setAvailableStockForSize] = useState(0);

    const { data: product, refetch, isLoading, error } = useGetProductDetailsQuery(productId);
    const [createReview, { isLoading: loadingProductReview }] = useCreateReviewMutation();
    const { userInfo } = useSelector((state) => state.auth);

    useEffect(() => {
        if (product && product.sizeArray && product.sizeArray.length > 0) {
            const availableSize = product.sizeArray.find(size => size.tshirtInStock > 0);
            if (availableSize) {
                setSelectedSize(availableSize.size);
                setAvailableStockForSize(availableSize.tshirtInStock);
            } else {
                setSelectedSize('');
                setAvailableStockForSize(0);
            }
        }
    }, [product]);

    useEffect(() => {
        if (selectedSize && product && product.sizeArray) {
            const sizeObj = product.sizeArray.find(s => s.size === selectedSize);
            if (sizeObj) {
                setAvailableStockForSize(sizeObj.tshirtInStock);
                setQty(1);
            } else {
                setAvailableStockForSize(0);
            }
        }
    }, [selectedSize, product]);

    const addToCartHandler = () => {
        dispatch(addToCart({ ...product, qty, size: selectedSize }));
        navigate('/cart');
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            await createReview({ productId, rating, comment }).unwrap();
            refetch();
            toast.success('Review submitted successfully');
            setRating(0);
            setComment('');
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    return (
        <>
            <StayTop />
            <Link className="btn btn-light my-3 orange-outline" onClick={() => navigate(-1)}>
                Go Back
            </Link>
            {isLoading ? (
                <Loader />
            ) : error ? (
                <Message variant='danger'>{error?.data?.message || error.error}</Message>
            ) : (
                <Container>
                    <h3>{product.name}</h3>
                    <Meta title={product.name} />
                    <Row className="flex-row-centered">
                        <Col xs={12} sm={12} md={12} lg={6} xl={7}>
                            <ProductsItemCarousel />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={5}>
                            <ProductDescription product={product} />
                            <ProductDetails
                                product={product}
                                selectedSize={selectedSize}
                                setSelectedSize={setSelectedSize}
                                availableStockForSize={availableStockForSize}
                                setAvailableStockForSize={setAvailableStockForSize}
                                qty={qty}
                                setQty={setQty}
                                addToCartHandler={addToCartHandler}
                                productId={productId}
                                showDiscount={product.isClearance}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TechnicalDetails product={product} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ProductReviews
                                product={product}
                                loadingProductReview={loadingProductReview}
                                userInfo={userInfo}
                                submitHandler={submitHandler}
                                rating={rating}
                                setRating={setRating}
                                comment={comment}
                                setComment={setComment}
                            />
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
};

const ProductDescription = ({ product }) => (
    <div className="product-description-large">
        <Card className="my-3">
            <Card.Body>
                <div className="product-description">
                    <h4>Product Description: </h4>
                    <p>{product.description.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}<br />
                        </React.Fragment>
                    ))}</p>
                </div>
            </Card.Body>
        </Card>
    </div>
);

const TechnicalDetails = ({ product }) => (
    <div className="product-description-small">
        <Card className="my-3">
            <Card.Body>
                <div className="product-description">
                    <h4>Technical Details: </h4>
                    <p>{product.productDetails.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}<br />
                        </React.Fragment>
                    ))}</p>
                </div>
            </Card.Body>
        </Card>
    </div>
);

const ProductReviews = ({
                            product,
                            loadingProductReview,
                            userInfo,
                            submitHandler,
                            rating,
                            setRating,
                            comment,
                            setComment
                        }) => (
    <Card className="my-3">
        <Card.Body>
            <div className="product-reviews mt-3">
                <h2>Reviews</h2>
                {product.reviews.length === 0 && <Message>No Reviews</Message>}
                <ListGroup variant='flush'>
                    {product.reviews.map((review) => (
                        <ListGroup.Item key={review._id}>
                            <strong>{review.name}</strong>
                            <Rating value={review.rating} />
                            <p>{review.createdAt.substring(0, 10)}</p>
                            <p>{review.comment}</p>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                <h2>Write a Customer Review</h2>
                {loadingProductReview && <Loader />}
                {userInfo ? (
                    <Form onSubmit={submitHandler}>
                        <Form.Group controlId='rating'>
                            <Form.Label>Rating</Form.Label>
                            <Form.Control
                                as='select'
                                value={rating}
                                onChange={(e) => setRating(e.target.value)}
                            >
                                <option value=''>Select...</option>
                                {[...Array(5).keys()].map(i => (
                                    <option key={i + 1} value={i + 1}>
                                        {i + 1}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId='comment'>
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                                as='textarea'
                                rows='3'
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            />
                        </Form.Group>
                        <Button
                            className="mt-2 blue-outline"
                            disabled={loadingProductReview}
                            type='submit'
                        >
                            Submit
                        </Button>
                    </Form>
                ) : (
                    <Message>Please <Link to='/login'>sign in</Link> to write a review</Message>
                )}
            </div>
        </Card.Body>
    </Card>
);

export default ProductScreen;
