import React, {useEffect} from 'react';

const StayTop = () => {
    return (
        useEffect(() => {
            window.scrollTo(0, 0)
        },
            [])
        )
};

export default StayTop;