import React from "react";
// import aboutImage from "./about-image.jpg"; // Import your image here
import capcom from '../assets/capcom.png'; // Assuming this and other imports are correctly set up
import { useNavigate, Link } from "react-router-dom";
import StayTop from "../components/StayTop";

const Terms = () => {
    const navigate = useNavigate();
    return (
      <>
          <StayTop />
            <Link className="btn btn-light my-3 orange-outline" onClick={() => navigate(-1)}>
                Go Back
            </Link>
            <div style={{ marginBottom: "40px" }}>
            <div className="container">
                <section className="terms">
                    <div className="terms">
                    <h2 className="about-heading">TERMS & CONDITIONS</h2>
                    <p><strong>
                        Part 1: WEBSITE TERMS OF USE
                        </strong></p>
                    <p><strong>INFORMATION ABOUT OUR TERMS OF USE</strong></p>

                    <p>1.1 These terms of use ('Terms') set out the rules for using our website <a href="www.Retrodayz.com">www.Retrodayz.com</a> ('Website').</p>

                    <p>1.2 By using the Website you agree that you accept these Terms and that you will comply with them.</p>

                    <p>1.3 If you do not agree to these Terms, you must not access or use the Website.</p>

                    <p>1.4 Please read these Terms carefully and print a copy for your future reference as we will not file a copy of these Terms and may also update them from time to time. If you have any queries then please contact us.</p>

                    <hr />

                        <p><strong>WHO WE ARE AND HOW TO CONTACT US</strong></p>

                        <p><strong>Who we are</strong></p>

                        <p>2.1 <a href="www.Retrodayz.com">www.Retrodayz.com</a> is a Website owned and operated by us, Kambells Limited (trading as Retrodayz).</p>

                        <p>2.2 We are a limited company registered in England and Wales under company number (Need to add the LTD number)). Our registered office address and main trading address is at (Need to address)). Our VAT number is GB (Need to add VAT number).</p>

                        <p><strong>How can I contact you?</strong></p>

                        <p>2.3 To contact us, please contact our customer services team by emailing the <a href="mailto:support@Retrodayz.co.uk">support@Retrodayz.co.uk</a> [or alternatively Retrodayz Social Media].</p>

                        <p>2.4 If you would like to make a complaint or feel that any material appearing on the Website is offensive, objectionable, or potentially defamatory please contact us via the details in section 2.3 above and provide full details of the nature of your complaint and the materials to which the complaint relates.</p>

                        <hr />

                            <p><strong>OTHER TERMS THAT MAY APPLY TO YOU</strong></p>

                            <p><strong>Are there any other terms which apply when I use the Website?</strong></p>

                            <p>3.1 These Terms refer to the following additional terms and conditions, which also apply to your use of our Website:</p>

                            <ul>
                                <li>Our Privacy Policy. Please see section 8 (How we may use your personal information) for further information about this.</li>
                                <li>Our Cookie Policy, which sets out information about the cookies on our Website.</li>
                            </ul>

                            <p><strong>Are there any other terms which could apply to me?</strong></p>

                            <p>3.2 If you are buying goods or services from our Website then our Terms and Conditions of Sale will apply to the sale of those goods and services to you.</p>

                            <hr />

                                <p><strong>WE MAY MAKE CHANGES TO THESE TERMS</strong></p>

                                <p><strong>Our right to make changes to the Terms</strong></p>

                                <p>4.1 We may update these Terms from time to time. We will give you reasonable notice of any major changes via a suitable announcement on the Website.</p>

                                <p><strong>When will the changes apply?</strong></p>

                                <p>4.2 The changes will apply to your use of the Website after we have given such announcement.</p>

                                <p><strong>What should I do if I don’t want to accept the changes?</strong></p>

                                <p>4.3 If you do not wish to accept the new terms you should not continue to use the Website. If you continue to use the Website after the date on which the change comes into effect, your use of the Website indicates your agreement to be bound by the new terms.</p>

                                <p>4.4 Please check these Terms to ensure you understand the terms and conditions that apply at that time.</p>

                                <hr />

                                    <p><strong>WE MAY MAKE CHANGES TO THE WEBSITE</strong></p>

                                    <p>We may update and change the Website from time to time. We will try to give you reasonable notice of any major changes.</p>

                                    <hr />

                                        <p><strong>USE OF OUR WEBSITE</strong></p>

                                        <p><strong>You must keep your account details safe</strong></p>

                                        <p>6.1 If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.</p>

                                        <p><strong>We may disable access to your account</strong></p>

                                        <p>6.2 We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of these Terms.</p>

                                        <p><strong>6.3</strong> If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at <a href="mailto:support@retrodayz.co.uk">support@retrodayz.co.uk</a>.</p>

                            <p><strong>Your use of the Website</strong></p>

                            <p><strong>6.4</strong> We only provide the Website for domestic and private use. You agree not to use the Website for any commercial or business purposes.</p>

                            <p><strong>6.5</strong> The Website may only be used for lawful purposes. You agree to comply with all applicable laws and regulations regarding the Website and its use.</p>

                            <p><strong>6.6</strong> You may retrieve and display the content of the Website on a computer screen, store such content in electronic form on disk or print a copy of such content for your own personal, non-commercial use. You may not otherwise reproduce, modify, copy or distribute or use for commercial purposes any of the materials or content on the Website.</p>

                            <p><strong>6.7</strong> Our status (and that of any identified contributors) as the authors of content on the Website must always be acknowledged.</p>

                            <p><strong>6.8</strong> You must not knowingly:</p>

                            <ul>
                                <li>upload or transmit through the Website any computer viruses, Trojans, worms, logic bombs or anything else designed to interfere with, interrupt or disrupt the normal operating procedures of a computer;</li>
                                <li>upload or transmit through the Website any material which is defamatory, offensive or of an obscene character;</li>
                                <li>attempt to gain unauthorized access to our Website, the server on which our Website is stored or any server, computer or database connected to our Website; or</li>
                                <li>attack our Website via a denial-of-service attack or a distributed denial-of service attack.</li>
                            </ul>

                            <p><strong>6.9</strong> By breaching section 6.8, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our Website will cease immediately.</p>

                            <p><strong>6.10</strong> We will not be responsible for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our Website or to your downloading of any material posted on it, or on any website linked to it.</p>

                            <p><strong>6.11</strong> We may disclose such information to law enforcement authorities as we reasonably feel is necessary or as required by law.</p>

                            <p><strong>6.12</strong> You acknowledge that we have no obligation to monitor your access to or use of the Website, but that we have the right to do so for the purpose of operating the Website, to ensure your compliance with these Terms, or to comply with applicable law or the order or requirement of a court, administrative agency or other governmental body. We reserve the right, at any time and without prior notice, to remove or disable access to any content that we, at our sole discretion, consider to be in violation of these Terms or otherwise harmful to the Website provided.</p>

                        <p><strong>7. UPLOADING CONTENT TO OUR WEBSITE</strong></p>

                        <p><strong>7.1</strong> Whenever you post content to our website, you agree and confirm that:</p>

                        <ul>
                            <li>you own or otherwise control all of the rights to the content that you post;</li>
                            <li>the content and material is accurate; and</li>
                            <li>use of the content and material will not cause injury to any person or entity (including that the content or material is not defamatory).</li>
                        </ul>

                        <p>You agree to indemnify us for all claims brought by a third party against us arising out of or in connection with a breach of this section 7.1. This means that you will be responsible for any loss or damage we suffer as a result of your breach of section 7.1.</p>

                        <p><strong>Your rights in the content which you post to our Website</strong></p>

                        <p><strong>7.2</strong> Any content uploaded to our Website will be considered non-confidential and non-proprietary. You will retain all of your ownership rights in your content but you are required to grant us (and our sub-licensees if required) a licence to use, copy, modify and adapt your content and to distribute and make it available to third parties (see section 7.6 for more information about this).</p>

                        <p><strong>7.3</strong> You agree to waive your right to be identified as the author of the content and your right to object to derogatory treatment of the content.</p>

                        <p><strong>We may disclose your identity to third parties in certain circumstances</strong></p>

                        <p><strong>7.4</strong> We may have to disclose your identity to a third party who claims that content posted or uploaded by you to our Website constitutes a violation of their intellectual property rights or privacy rights.</p>

                        <p><strong>We may remove your content</strong></p>

                        <p><strong>7.5</strong> We may remove any post you make on our Website if in our opinion your post does not comply with our guidelines.</p>

                        <p><strong>Rights you are giving us to use content uploaded by you to our Website</strong></p>

                        <p><strong>7.6</strong> By uploading or posting content to our Website, you grant us the following rights to use that content:</p>

                        <ul>
                            <li>a non-exclusive, royalty-free, perpetual,</li>
                            <li>irrevocable, and fully sub-licensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media; and</li>

                            <li>grant us and our sub-licensees the right to use the name that you submit in connection with such content.</li>
                        </ul>

                        <p><strong>7.7</strong> If we reasonably request you to do so, you agree to do such further acts and execute all documents which may from time to time be necessary to give full effect to this section 7.</p>

                        <p><strong>8. HOW WE USE YOUR PERSONAL INFORMATION</strong></p>

                        <p><strong>8.1</strong> We will treat all your personal information as confidential and will only use it in accordance with our Privacy Policy. In addition, we will process information about you in accordance with our Privacy Policy.</p>

                        <p><strong>8.2</strong> By using our Website, you:</p>

                        <ul>
                            <li>consent to such processing and you warrant that all data provided by you is accurate; and</li>
                            <li>authorise us to transmit information (including updated information) to obtain information from third parties, including but not limited to, your debit or credit card numbers or credit reports to authenticate your identity, to validate your credit card, to obtain an initial credit card authorisation and to authorise individual purchase transactions.</li>
                        </ul>

                        <p><strong>9. OUR INTELLECTUAL PROPERTY RIGHTS IN OUR WEBSITE</strong></p>

                        <p><strong>Our intellectual property rights in the Website and its content</strong></p>

                        <p><strong>9.1</strong> Other than in relation to links to third party websites, we or our licensors are the owners or the licensee of all intellectual property rights in the Website and in the material published on it, including all brand names and trademarks. All such rights are reserved and nothing in these Terms will be interpreted as giving you ownership or rights in such intellectual property or the data contained therein.</p>

                        <p><strong>9.2</strong> You are not permitted to use our intellectual property without our approval, unless expressly permitted under the section 6 (Use of our Website).</p>

                        <p><strong>10. WE ARE NOT RESPONSIBLE FOR OTHER WEBSITES WHICH WE LINK TO</strong></p>

                        <p><strong>10.1</strong> As a convenience to you, the Website may include links to other websites or material which is provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.</p>

                        <p><strong>10.2</strong> We have no control over the content of those websites or resources and we accept no responsibility for the availability, suitability or content of such websites and nor do we review or endorse them or any views expressed within them.</p>

                        <p><strong>10.3</strong> We will not be responsible for the privacy practices or content of such websites nor will we be responsible for any damage, loss or offence caused or alleged to be caused that may arise from your use of them.</p>

                        <p><strong>11. DO NOT RELY ON INFORMATION ON OUR WEBSITE</strong></p>

                        <p><strong>11.1</strong> The content on our Website is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our Website.</p>

                        <p><strong>11.2</strong> Although we make reasonable efforts to update the information on our Website, we make no representations, warranties or guarantees, whether express or implied, that the content on our Website is accurate, complete or up to date.</p>

                        <p><strong>11.3</strong> If we are informed of any inaccuracies in the material on the Website we will correct this as soon as we reasonably can.</p>

                        <p><strong>12. OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU</strong></p>

                        <p><strong>12.1</strong> We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and any liability for fraud or fraudulent misrepresentation.</p>

                        <p><strong>12.2</strong> We provide you with access to the Website free of charge and subject to section 12.1 above, we will not be responsible for any loss, injury or damage of whatever kind caused in whole or in part by:</p>

                        <ul>
                            <li>incompatibility of the Website with any of your equipment, software or telecommunications links;</li>
                            <li>technical problems including errors or interruptions of the Website;</li>
                            <li>unsuitability, unreliability or inaccuracy of the Website; and</li>
                            <li>failure of the Website to meet your requirements.</li>
                        </ul>

                        <p><strong>12.3</strong> The Website is provided on an 'as is' and 'as available' basis without any representation or endorsement made and we make no warranties in relation to it and its use. You acknowledge that we cannot guarantee and cannot be responsible for the security or privacy of the Website and any information provided by you. You must bear the risk associated with the use of the internet.</p>

                        <p><strong>13. OUR RIGHTS IF YOU BREACH THESE TERMS</strong></p>

                        <p><strong>13.1</strong> Should you make any illegal and/or unauthorised use of the Website, and/or fail to comply with these Terms, we may take such action as we reasonably determine to be appropriate including amongst other measures temporarily or permanently removing your registration and right to use the Website.</p>

                        <p><strong>14. WE MAY SUSPEND OR WITHDRAW OUR WEBSITE</strong></p>

                        <p><strong>14.1</strong> Our Website is made available free of charge.</p>

                        <p><strong>14.2</strong> We may suspend or withdraw all or any part of our Website for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.</p>

                        <p><strong>14.3</strong> We may disable any user identification code or password we have provided to you, whether chosen by you or allocated by us, at any time, if in our opinion you have failed to comply with any of these Terms.</p>

                        <p><strong>15. [OUR WEBSITE IF ONLY FOR USERS IN THE UK</strong></p>

                        <p>The Website is directed to people residing in the United Kingdom. We do not represent that content available on or through the Website is appropriate for use or available in other locations.]</p>

                        <p><strong>16. NOBODY ELSE HAS RIGHTS UNDER THESE TERMS</strong></p>

                        <p>Only you and us are entitled to enforce these Terms. No other person will be entitled to enforce any provision of these Terms.</p>

                        <p><strong>17. WHAT HAPPENS IF PARTS OF THESE TERMS BECOME INVALID?</strong></p>

                        <p>If any part of the Terms is deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions of the Terms.</p>

                        <p><strong>18. EVEN IF WE DELAY ENFORCING THESE TERMS, WE CAN STILL ENFORCE THEM LATER</strong></p>

                        <p>Any delay or failure by us in exercising, or any waiver by us of, our rights under or in connection with these Terms will not limit or restrict the future exercise or enforceability of those rights.</p>

                        <p><strong>19. WHICH COUNTRY’S LAWS APPLY TO ANY DISPUTES?</strong></p>

                        <p>These Terms shall be governed by and construed in accordance with the laws of England and Wales and any disputes will be decided only by the English courts except that if you are acting as a consumer and are a resident of Northern Ireland you may also bring proceedings in Northern Ireland and if you are a consumer and a resident in Scotland you may also bring proceedings in Scotland.</p>

                        <p><strong>Part 2: GENERAL TERMS AND CONDITIONS OF SALE</strong></p>

                        <p><strong>1. INFORMATION ABOUT THESE TERMS</strong></p>

                        <p>Why these Terms are important</p>

                        <p>1.1 These terms and conditions ('Terms of Sale') set out the terms and conditions which apply to our, RetrodayzGroup Limited (‘us’, ‘we’ and ‘our’), sale of goods and services (together, the ‘products’) to you via our website <a href="www.Retrodayz.com">'Website'</a>.</p>

                        <p>1.2 Please read these Terms of Sale carefully before you submit an order to us as they affect your rights and liabilities under law and tell you who we are, how we will provide goods and services to you, how you and we may change or end the contract, what to do if there is a problem and other important information.</p>

                        <p>1.3 We recommend that you print a copy of these Terms of Sale for your future reference as we will not file a copy of these Terms of Sale specifically in relation to your order.</p>

                        <p>Important parts of these Terms of Sale</p>

                        <p>1.4 We would especially like to draw your attention to the following sections:</p>

                        <ul>
                            <li>sections 14 (Your rights to end the contract) and 15 (Cancelling and returning products if you change your mind) which sets out your rights to cancel orders placed by you;</li>
                            <li>section 16 (Our rights to end the contract) which sets out our rights to cancel orders placed by you;</li>
                            <li>section 17 (Faulty, damaged or incorrect products) which sets out your rights if products are faulty, damaged or incorrect; and</li>
                            <li>section 18 (Our responsibility for loss or damage suffered by you) which sets out our responsibility to you.</li>
                        </ul>

                        <p>Let us know if you have any questions about these Terms of Sale</p>

                        <p>1.5 If you have any queries regarding these Terms of Sale then please contact our customer services team by logging on to your account via our Help Centre or alternatively Retrodayz.</p>

                        <p><strong>2. WHO WE ARE AND HOW TO CONTACT US</strong></p>

                        <p><strong>Who we are</strong></p>

                        <p>2.1 We are, (Limited company name to go here) (trading as Retrodayz).</p>

                        <p>2.2 We are a limited company registered in England and Wales under company number (Retrodayz company Number), whose registered office address is at `(Office Address to go here)`. Our main trading address is Retrodayz. Our VAT number is (My VAT no. to go here)).</p>

                        <p><strong>How to contact us</strong></p>


                        <p>2.3 To contact us, (add same contacts as above))</p>

                        <p>2.4 If we have to contact you we will do so by telephone or by writing to you at the email address or postal address you provide to us in your order.</p>

                        <p>How can I contact you if I have a complaint?</p>

                        <p>2.5 We try our best to ensure that your experience as a customer of ours is a positive one and want to hear from you if you have any cause for complaint.</p>

                        <p>2.6 If you have any queries, complaints or problems with the products, please contact our customer services team by emailing on the <a href="mailto:support@retrodayz.co.uk">support@retrodayz.co.uk</a> email address and provide full details of the nature of your complaint.</p>

                        <p>Will my contract always be with you?</p>

                        <p>2.7 In some circumstances your contract may be with one of our group companies rather than us. Please see below for more detail about this:</p>

                        <p>3. OTHER TERMS THAT MAY APPLY TO YOU</p>

                        <p>3.1 These Terms of Sale refer to the following additional terms and conditions, which also apply to your purchase of the products:</p>

                        <ul>
                            <li>Our Returns Policy, please see sections 14 (Your rights to end the contract) and section 15 (Cancelling and returning products if you change your mind) for more information about this.</li>
                            <li>Our Privacy Policy, please see section 5 (How we may use your personal information) for more information about this.</li>
                            <li>Our Cookie Policy, which sets out information about the cookies on our Website.</li>
                        </ul>

                        <p>4. YOUR STATUS</p>

                        <p>Confirmation of your status</p>

                        <p>4.1 By placing an order with us, you are confirming that:</p>

                        <ul>
                            <li>you are legally capable of entering into binding contracts;</li>
                            <li>you are at least 16 years of age;</li>
                            <li>you are a consumer (i.e. for private use as opposed to business use); and</li>
                            <li>the personal information which you are required to provide when you register is true, accurate, current and complete in all respects; and you are not impersonating any other person or entity.</li>
                        </ul>

                        <p>Do I need to notify you of any changes to my personal information and confirmation?</p>

                        <p>4.2 You must notify us immediately of any changes to your personal information by e-mailing or telephoning our customer service representatives</p>

                        <p>5. HOW WE USE YOUR PERSONAL INFORMATION</p>

                        <p>5.1 We will treat all your personal information as confidential and will only use it in accordance with our Privacy Policy. In addition, we will process information about you in accordance with our Privacy Policy. By using our Website, you consent to such processing and you warrant that all data provided by you is accurate.</p>

                        <p>5.2 When you shop on this Website, we will ask you to input personal details in order for us to identify you, such as your name, e-mail address, billing address, delivery address, credit card or other payment information. We confirm that this information will be held by us in accordance with the registration we have with the Data Commissioner's office.</p>

                        <p>5.3 You authorise us to transmit information (included updated information) to obtain information from third parties, including but not limited to, your debit or credit card numbers or credit reports to authenticate your identity, to validate your credit card, to obtain an initial credit card authorisation and to authorise individual purchase transactions.</p>

                        <p>6. PROTECTING YOUR SECURITY</p>

                        <p>How we validate your payment</p>

                        <p>6.1 To ensure that your credit, debit or charge card is not being used without your consent, we will validate the name, address and other personal information supplied by you during the order process against appropriate third party databases.</p>

                        <p>6.2 We take the risk of internet fraud very seriously. With the volume of fraudulent credit card transactions increasing, we make every effort to ensure all orders are thoroughly checked using the information already supplied. There is a possibility we may contact you to make additional security checks and we ask for your co-operation to enable us to complete them. We will not tolerate fraudulent transactions and such transactions will be reported to the relevant authorities.</p>

                        <p>6.3 By accepting these Terms of Sale you consent to such checks being made. In performing these checks personal information provided by you may be disclosed to a registered Credit Reference Agency which may keep a record of that information. You can rest assured that this is done only to confirm your identity, that a credit check is not performed and that your credit rating will be unaffected. All information provided by you will be treated securely and strictly in accordance with the Data Protection Act 2018.</p>

                        <p>7. OUR PRODUCTS</p>

                        <p>Products may vary from their pictures</p>

                        <p>7.1 We have made every effort to ensure that the products conform to the photographs and descriptions provided on our Website. However, please note that certain colours may look different to the actual colour of the products, when displayed on an electronic device.</p>

                        <p>Availability of products</p>

                        <p>7.2 We do not represent or warrant that particular products will be available. Please see section 9.6 (What happens if we cannot accept your order) for information about what happens if the products are not available.</p>

                        <p>Personalising your goods</p>

                        <p>7.3 You may have the option to personalise certain goods. Please ensure the personalisation information you provide is correct and any images are of a sufficiently high resolution, as the goods will be manufactured using this information.</p>

                        <p>7.4 If you have chosen to personalise the goods you may lose your right to cancel your order (see section 15 (Cancelling and returning goods if you change your mind) for further details) as it may be the case that we are unable to resell the goods due to the personalisation. Similarly, we cannot accept the return of any personalised goods if the return is due to incorrect information provided by you.</p>

                        <p>8. OUR RIGHT TO MAKE CHANGES</p>

                        <p>8.1 We have the right to make minor changes to the products without notifying you in order to:</p>

                        <ul>
                            <li>conform with any applicable safety or other legal or regulatory requirements; or</li>
                            <li>implement minor technical adjustments and improvements. These changes will not affect your use of the products.</li>
                        </ul>

                        <p>8.2 We may also make reasonable changes to the products or these Terms of Sale but if we do so we will notify you and you will have the right to contact us to end the contract before the changes take effect and receive a refund for any products paid for but not received.</p>

                        <p>9. OUR CONTRACT WITH YOU</p>

                        <p>How to place an order with us</p>

                        <p>9.1 Our Website will guide you through the ordering process.</p>

                        <p>9.2 Before submitting your order to us, you will be given the opportunity to review and amend it. Please ensure that you have checked your order and that you have read these Terms of Sale carefully before submitting your order. If you are unsure about any part of these Terms of Sale, please ask us for clarification.</p>

                        <p>How we accept your order</p>

                        <p>9.3 Our acceptance of your order will take place when we email you confirming our acceptance (‘Order Confirmation’), at which point a contract between you and us will come into existence.</p>

                        <p>9.4 Automated acknowledgements of your order which you may receive from us do not amount to our acceptance of your offer to purchase products advertised on our website.</p>

                        <p>What will the contract cover?</p>

                        <p>9.5 The contract will relate only to those products confirmed in the Order Confirmation. We will not be obliged to supply any other products which may have been part of your order until the dispatch of such products has been confirmed in a separate Order Confirmation.</p>

                        <p>What happens if we cannot accept your order?</p>

                        <p>9.6 If we cannot accept your order, you will be notified by e-mail (or by other means if no e-mail address has been provided) and you will have the option either to wait until the item is available from stock or to cancel your order. This may be because the product is unavailable or because we have identified an error in the price or description of the product.</p>

                        <p>9.7 If we have taken payment, we will be refund you as soon as possible (and in any event, within 14 days).</p>

                        <p>Can I make changes to orders accepted by you?</p>

                        <p>9.8 Before submitting your order to us, you will be given the opportunity to review and change it. Please make sure you have checked your order carefully before submitting your order as once submitted you will not be able to make changes to it.</p>

                        <p>Our goods and services are not for resale</p>

                        <p>9.9 The products sold on the Website are not for re-sale or distribution. We reserve the right to cancel orders and/or suspend accounts where we believe products are being ordered in breach of this provision.</p>

                        <p>[We only sell to the UK</p>

                        <p>9.10 Our Website is solely for the promotion of our goods and services in the UK. Unfortunately, we do not accept orders from addresses outside the UK.]</p>

                        <p>10. DELIVERY</p>

                        <p>Delivery times and costs</p>

                        <p>10.1 Information about delivery times and costs will be set out at ‘Checkout’ and orders will be delivered to the address you specify. All goods will normally be dispatched within Retrodayz of receipt of your order.</p>

                        <p>10.2 If the products are one-off services, we will begin the services on the date agreed with you during the order process. The completion date for the services is as told to you during the order process.</p>

                        <p>10.3 If your order has not arrived by the estimated delivery date, please contact us at Retrodayz as soon as possible so we can investigate.</p>

                        <p>What happens if you do not collect the goods or are not at home when the goods are delivered?</p>

                        <p>10.4 If you are not home when the goods are delivered we may leave the goods in a safe location or, if no one is available to accept delivery, re-deliver the goods on a different day.</p>

                        <p>What happens if we fail to deliver the goods in time?</p>

                        <p>10.5 In the unlikely event that we fail to deliver within the time specified or, if no time has been specified, within 30 days of our Order Confirmation (or as otherwise agreed), you may end the contract if:</p>

                        <ul>
                            <li>failure to deliver was caused by us refusing to deliver your goods;</li>
                            <li>in light of all relevant circumstances, delivery within that time period was essential; or</li>
                            <li>you told us when ordering the goods that delivery within that time period was essential.</li>
                        </ul>

                        <p>10.6 If you do not wish to cancel, or none of the circumstances described above apply, you may specify a new (reasonable) delivery date. If we fail to meet the new deadline, you may end the contract.</p>

                        <p>10.7 If you have the right to end the contract under sections 10.5 or 10.6 you may instead cancel or reject part of your order provided that separating the goods in your order would not significantly reduce their value.</p>

                        <p>10.8 Any sums that you have already paid for cancelled goods and their delivery will be refunded to you within 14 days.</p>

                        <p>10.9 Please note that if any cancelled goods are delivered to you, you must return them to us or arrange with us for their collection within 14 days from the date of cancellation or delivery (whichever is later). In either case, we will bear the cost of returning the cancelled goods.</p>

                        <p>What happens if you do not allow us to provide the services?</p>

                        <p>10.10 If you do not allow us access to your property to perform the services as arranged (and you do not have a good reason for this) we may charge you additional costs incurred by us as a result. If, despite our reasonable efforts, we are unable to contact you or re-arrange access to your property we may end the contract and section 16 (Our rights to cancel the contract) will apply.</p>

                        <p>When does delivery take place?</p>

                        <p>10.11 Delivery will be deemed to have taken place when the goods have been delivered to the delivery address specified by you in your order and you (or someone identified by you) have taken physical possession of the goods.</p>

                        <p>Who is responsible for the goods during delivery?</p>

                        <p>10.12 The responsibility (sometimes referred to as ‘risk’) for the goods remains with us until the goods have been delivered to the delivery address specified by you in your order. We accept no liability where you provide an incorrect delivery address or where you fail to collect the goods from the delivery address which you specified.</p>

                        <p>When do you own the goods?</p>

                        <p>10.13 You own the goods only once we have received payment in full of all sums due (including any delivery charges).</p>

                        <p>11. PRICE AND PAYMENT</p>

                        <p>Prices and other charges</p>

                        <p>11.1 All prices (including delivery charges) shown on the website are inclusive of VAT (where applicable) at the current rates and are correct at the time of entering the information onto the system. We reserve the right, however, to change prices at any time without notice to you (save that any changes will not affect orders in respect of which we have sent you a Order Confirmation).</p>

                        <p>When and how you pay</p>

                        <p>11.2 Payment can be made by any major credit or debit card or via your PayPal account. Payment will be debited and cleared from your account on the dispatch of your goods or provision of the service to you.</p>

                        <p>11.3 You confirm that the credit, debit card or PayPal account that is being used is yours. All credit/debit cardholders are subject to validation checks and authorisation by the card issuer. If the issuer of your payment card refuses to or does not, for any reason, authorise payment to us we will not be liable to you for any delay or non-delivery.</p>

                        <p>11.4 If your credit or debit card payment is not processed successfully for any reason, we reserve the right to reattempt to process payment within 48 hours. In the event that the payment is still unsuccessful, we will give you at least 48 hours’ notice in advance of any further reattempt to process payment by sending an email to the email address you have provided to us. If you do not want us to reattempt to process payment, you must cancel your order in advance.</p>

                        <p>What happens if we got the price wrong?</p>

                        <p>11.5 In the unlikely event that the price shown on the checkout page is wrong, and we discover this before accepting your order in accordance with section 9.3, we are not required to sell the products to you at the price shown.</p>

                        <p>11.6 We always try and ensure that the prices of goods shown on our Website are accurate, but occasionally genuine errors may occur. If we discover an error in the price of the goods that you have ordered we will let you know as soon as possible and give you the option of reconfirming your order at the correct price or cancelling it. If you cancel your order and you have already paid for the goods (but they have not yet been dispatched), then you will receive a full refund.</p>

                        <p>Discount codes</p>

                        <p>11.7 We allow you to use discount codes strictly on the terms and conditions upon which they were issued which, amongst other things, may include terms relating to your eligibility to use them and a maximum order value. Exclusions may apply on the discount code you are using. Please ensure to check the terms and conditions of each offer or visit our help page for further details. <a href="https://www.Retrodayz.com/help-centre.list">https://www.Retrodayz.com/help-centre.list</a></p>

                        <p>11.8 Please familiarise yourself with these terms and conditions before you place an order as we reserve the right to reject or cancel any orders which do not comply with these terms even if your credit or debit card has been charged. If there is any inconsistency between the terms and conditions upon which the discount codes were issued and these terms and conditions, the discount code terms and conditions prevail.</p>

                        <p>11.9 A copy of the discount code terms and conditions can be obtained by e-mailing <a href="mailto:support@retrodayz.co.uk">support@retrodayz.co.uk</a>.</p>

                        {/*12. GIFT VOUCHERS*/}

                        {/*12.1 These Terms of Sale also apply to the purchase of your gift voucher.*/}

                        {/*When can I use a Retrodayz Gift Voucher?*/}

                        {/*12.2 The Retrodayz Gift Voucher can be used to buy any item sold by www. Retrodayz.com.*/}

                        {/*12.3 You can email it to family and friends, or print it out to give to them personally. It is the perfect way to give a gift when you don’t know what to buy!*/}

                        {/*What is a Retrodayz Gift Voucher?*/}

                        {/*12.4 The Retrodayz Gift Voucher is available in £10, £25 and £50 denominations and enables you to give a gift when you don’t know what to buy.*/}

                        {/*12.5 Gift Vouchers are issued via email using an electronic voucher code which can then be redeemed at the Checkout to buy any product on www. Retrodayz.com.*/}

                        {/*How do I order a Gift Voucher?*/}

                        {/*12.6 You can add the Gift Voucher to your shopping basket like any other product by selecting the ‘Add to Basket’ button found on the ‘Buy Now’ tab. Then continue to checkout to complete your order.*/}

                        {/*12.7 If you want to purchase more than one Gift Voucher you can increase the item quantity on the basket page or go back to the relevant Gift Voucher product page and add more items to your basket. You can also buy other products at the same time as placing your order for Gift Voucher(s).*/}

                        {/*What currency are Gift Vouchers issued in?*/}

                        {/*12.8 Gift Vouchers are valued and issued in GBP Pounds.*/}

                        {/*Can I use a discount code when purchasing a Gift Voucher?*/}

                        {/*12.9 Discount codes cannot be used when purchasing a Gift Voucher.*/}

                        {/*How will I receive a Gift Voucher?*/}

                        {/*12.10 Once your order has been processed and we have taken payment, you will be emailed the electronic Gift Voucher code to the email address where your order confirmation email is also sent. You will not receive anything in the post for Gift Vouchers that you order.*/}

                        {/*How do I give the Gift Voucher to the recipient?*/}

                        {/*12.11 Once you have received the electronic Gift Voucher code you can then forward on this email to the gift recipient, or you can print out the voucher and give it to them.*/}

                        {/*12.12 Please note that Gift Vouchers are valid for 12 months from the date of purchase.*/}

                        {/*I am the recipient of a Gift Voucher, how do I redeem it?*/}

                        {/*12.13 Gift Vouchers can be used to purchase any product on www. Retrodayz.com. Select the item(s) you wish to order and add them to your basket. To apply your Gift Voucher, enter the electronic voucher code you received in the ‘Discount Code’ box and click the ‘Add’ button. This will then apply the voucher to your order. Then proceed to the Checkout as normal.*/}

                        {/*What are the restrictions?*/}

                        {/*12.14 The following restrictions apply to your use of the Gift Vouchers:*/}

                        {/*• You can only use one voucher per order and no change will be issued if the full value of the voucher is not used in the order.*/}

                        {/*• Gift Vouchers are valid for 12 months from the date of purchase so make sure you remember to redeem your Gift Voucher in time.*/}

                        {/*• Gift Vouchers can only be used on www. Retrodayz.com.*/}

                        {/*• Gift Vouchers are valued and issued in GBP Pounds.*/}

                        {/*• You cannot use your Gift Voucher in conjunction with any other discount code.*/}

                        {/*I have paid for an order using a Gift Voucher. What happens if I want to return my order?*/}

                        {/*12.15 For orders which are refunded, the refund will be issued via the same payment method as used to pay for the order. Therefore if you have paid, or part-paid, for an order using a Retrodayz Gift Voucher any refund will be issued in Gift Vouchers to the same value.*/}

                        {/*I have lost a Gift Voucher. What do I do?*/}

                        {/*12.16 If you are the recipient of a Retrodayz Gift Voucher please ask the purchaser if they still have details of your Voucher Code. If not, please ask the purchaser to contact us via Retrodayz so that the Gift Voucher can be reissued. We can only reissue Gift Vouchers which have not been redeemed.*/}

                        {/*12.17 Please note the original Gift Voucher will be void if we issue a replacement and the original expiry date will still apply.*/}

                        {/*When do you own the Gift Voucher and who is responsible for the Gift Voucher?*/}

                        {/*12.18 The risk of loss and title for a Gift Voucher shall pass to the purchaser upon our electronic transmission of the Gift Voucher to the purchaser or designated recipient, whichever is applicable. We are not responsible if any Gift Voucher is lost, stolen, destroyed or used without your permission.*/}

                        {/*Our rights if you use a Gift Voucher which has been fraudulently obtained*/}

                        {/*12.19 We will have the right to close customer accounts and take payment from alternative forms of payment if a fraudulently obtained Gift Voucher is redeemed and/or used to make purchases on www. Retrodayz.com.*/}

                        {/*Can I return my Gift Voucher?*/}

                        {/*12.20 Gift Vouchers cannot be returned or refunded, except in accordance with your statutory rights. The value of the Gift Voucher cannot be converted back to cash.*/}

                        {/*Credit*/}

                        {/*12.21 Any credit will be valid for 3 months from the date of issue.*/}

                        {/*13. REFERRAL SCHEME*/}

                        {/*13.1 Subject to sections 13.2 to 13.10 (inclusive) below, you may earn ‘credit’ for use on our Website by referring a new customer to us through our referral scheme by sharing the referral link and/or referral code we provide to you (the “Referral Scheme”).*/}

                        {/*Eligibility*/}

                        {/*13.2 You are only eligible to participate as a ‘referrer’ in this Referral Scheme if you have previously placed an order on our Website.*/}

                        {/*What counts as a new customer?*/}

                        {/*13.3 A new customer is a natural person who has not previously placed an order with us (a "Referee").*/}

                        {/*13.4 If the Referee has an account on our Website but has not previously placed an order on our Website then they are entitled to participate in the Referral Scheme through the referral link or code provided.*/}

                        {/*How does the referral scheme work?*/}

                        {/*13.5 If the Referee has clicked through your referral link the discount code will automatically be applied at checkout. If the Referee is using a referral code, the Referee must enter this code at checkout.*/}

                        {/*Are there any restrictions which apply?*/}

                        {/*13.6 The Referee must place an order with a value of at least the minimum value as stated on our Website from time to time.*/}

                        {/*13.7 All discounted products and subscription products are excluded from the Referral Scheme. If you are participating in the Referral Scheme, you will be unable to participate in any affiliate schemes, cash-back offers and/or any other promotions of this nature.*/}

                        {/*13.8 Any credit generated from the Referral Scheme may only be spent on our Website and neither you nor the Referee is entitled to receive any payment of any kind in respect of the credit. The credit is not redeemable for cash. The credit that stands to your Referral Scheme account is not transferable in any way.*/}

                        {/*13.9 You will receive the applicable credit on your account 24 hours after dispatch of the Referee's order. If the Referee cancels their order for any reason within 3 days of it being dispatched, you will not qualify for any credit. You will receive your credit in the default currency of our Website.*/}

                        {/*We may withdraw your credit*/}

                        {/*13.10 We may, in our sole discretion, withdraw your credit generated from the Referral Scheme at any time if we believe or suspect that your use of the Referral Scheme is fraudulent, misleading or contrary to these Terms of Sale.*/}

                        {/*14. YOUR RIGHTS TO END THE CONTRACT*/}

                        {/*14.1 You can always end your contract with us. Your rights when you end the contract will depend on what you have bought, whether there is anything wrong with it, how we are performing and when you decide to end the contract:*/}

                        {/*• if what you have bought is faulty or misdescribed you may have a legal right to end the contract (or to get the product repaired or replaced or a service re-performed or to get some or all of your money back), see section 17 (Faulty, damaged or incorrect products) for more information about this;*/}

                        {/*• If you want to end the contract because of something we have done or have told you we are going to do, see section 14.2 below;*/}

                        {/*• if you have just changed your mind about the product, see section 15 (Cancelling and returning products if you change your mind). You may be able to get a refund if you are within the 14 day cooling-off period, but this may be subject to deductions and you will have to pay the costs of return of any goods;*/}

                        {/*• in all other cases (if we are not at fault and there is no right to change your mind), see section 14.3 below.*/}

                        {/*Ending the contract because of something we have done or are going to do.*/}

                        {/*14.2 If you are ending a contract for a reason set out below the contract will end immediately and we will refund you in full for any products which have not been provided and you may also be entitled to compensation. The reasons are:*/}

                        {/*• we have told you about an upcoming change to the product or these terms which you do not agree to (see section 8.2);*/}

                        {/*• we have told you about an error in the price or description of the product you have ordered and you do not wish to proceed;*/}

                        {/*• there is a risk that supply of the products may be significantly delayed because of events outside of our control (see section 19 (Events outside of our control) for more information about this);*/}

                        {/*• we have suspended supply of the products for technical reasons, or notify you that we are going to suspend them for technical reasons, in each case for a period of more than [28 days]; or*/}

                        {/*• you have a legal right to end the contract because of something we have done wrong (including because we have delivered late (see sections 10.5 and 10.6 (What happens if we fail to deliver the goods in time?).*/}

                        {/*Ending the contract where we are not at fault and there is no right to change your mind*/}

                        {/*14.3 Even if we are not at fault and you do not have a right to change your mind, you can still end the contract before it is completed. A contract for goods is completed when the product is delivered and paid for. A contract for services is completed when we have finished providing the services and you have paid for them. If you want to end the contract before it is completed, just contact us to let us know. [The contract will not end until [one day] after the day on which you contact us.]*/}

                        {/*14.4 We will refund any advance payment you have made for products which will not be provided to you.*/}

                        <p>12. CANCELLING AND RETURNING GOODS IF YOU CHANGE YOUR MIND</p>

                        <p>How long have I got to change my mind?</p>

                        <p>12.1 As you are purchasing the products as a consumer (i.e. for private use as opposed to business use), you have a 14 day right to cancel the contract between us and you for any reason (this is often called the ‘cooling off period’).</p>

                        <p>12.2 For goods, the 14 day period starts running on the day on which you acquire physical possession of the goods (i.e. the day the goods are delivered to you). If you have ordered multiple products which are delivered on different days, your right to cancel will expire 14 days after the delivery of the last product.</p>

                        <p>12.3 For services, the 14 day period starts running after the day we email you to accept your order. However, once we have completed the services you cannot change your mind, even if the 14 day period is still running. If you cancel after we have started the services, you must pay us for the services provided up until the time you tell us that you have changed your mind.</p>

                        <p>12.4 For some products we may give you a longer period during which you can cancel the contract. Please check our Returns Policy for more information about this.</p>

                        <p>How to let us know if you change your mind</p>

                        <p>12.5 Please see our Returns Policy which sets out information on how you can notify us. Alternatively you can use our model cancellation form.</p>

                        <p>How do I arrange for the products to be returned?</p>

                        <p>12.6 Please see our Returns Policy which sets out information on how you can arrange for products to be returned to us.</p>

                        <p>You are responsible for the costs of return</p>

                        <p>12.7 Unless stated otherwise in our Returns Policy, you are responsible for the cost of returning goods.</p>

                        <p>There are circumstances where you may lose your right to cancel</p>

                        <p>12.8 Please note that you may lose your legal right to cancel if:</p>

                        <ul>
                            <li>the Goods have been personalised or custom-made for you, as described in section 7.4 (Personalised Goods);</li>
                            <li>we are providing you with services and you have received such services, in which case we can charge you a reasonable amount for the services received up to the time of cancellation;</li>
                            <li>you have mixed the goods inseparably with another item after delivery; or</li>
                            <li>the goods were sealed for hygiene reasons and become unsealed after delivery.</li>
                        </ul>

                        <p>Refunds</p>

                        <p>12.9 Refunds under this section 12 will be issued to you within 14 days from:</p>

                        <ul>
                            <li>the day on which we receive the products back;</li>
                            <li>the day on which you inform us (with evidence) that you have sent the products back (if this is earlier than the day we receive the products); or</li>
                            <li>if we have not yet provided any Order Confirmation or have not yet dispatched the products, the day on which you inform us that you wish to cancel the contract.</li>
                        </ul>

                        <p>12.10 The maximum refund for delivery costs will be the costs of delivery by the least expensive delivery method we offer. For example, if we offer standard delivery of a product within 3-5 days at one cost but you choose next day delivery instead at a higher cost, then we will only refund what you would have paid for the cheaper delivery option (i.e. standard delivery).</p>

                        <p>12.11 Refunds under this section 12 will be made using the same payment method that you used when ordering the products, unless you specifically request that we make a refund using a different method.</p>

                        <p>We may reduce the refund if you have used the product</p>

                        <p>12.12 Refunds may be reduced for any diminished value in the products resulting from your excessive handling of them (e.g. more than would be permitted in a shop). If we issue a refund before we have received the products and have had a chance to inspect them, we may subsequently charge you an appropriate sum if we find that the products have been handled excessively.</p>

                        <p>12.13 Where the product is a service, we may deduct from any refund an amount for the supply of the service for the period for which it was supplied, ending with the time when you told us you had changed your mind. The amount will be in proportion to what has been supplied, in comparison with the full coverage of the contract.</p>

                        <p>13. OUR RIGHTS TO END THE CONTRACT</p>

                        <p>We may end the contract if you break it</p>

                        <p>13.1 We may end the contract for a product at any time by writing to you if:</p>

                        <ul>
                            <li>you do not make any payment to us when it is due and you still do not make payment within Retrodayz days of us reminding you that payment is due;</li>
                            <li>you do not, within a reasonable time of us asking for it, provide us with information that is necessary for us to provide the products;</li>
                            <li>you do not, within a reasonable time, allow us to deliver the products to you or collect them from us;</li>
                            <li>you do not, within a reasonable time, allow us access to your premises to supply the services.</li>
                        </ul>

                        <p>You must compensate us if you break the contract</p>

                        <p>13.2 If we end the contract in the situations set out in section 13.1 we will refund any money you have paid in advance for products we have not provided but we may deduct or charge you reasonable compensation for the net costs we will incur as a result of your breaking the contract.</p>

                        <p>We may withdraw the product</p>

                        <p>13.3 We may write to you to let you know that we are going to stop providing the product. We will let you know at least Retrodayz in advance of our stopping the supply of the product and will refund any sums you have paid in advance for products which will not be provided.</p>

                        <p>14. FAULTY, DAMAGED OR INCORRECT GOODS</p>

                        <p>Your right to receive goods of a satisfactory quality</p>

                        <p>14.1 By law, we must provide products and services that are of satisfactory quality, fit for purpose, as described at the time of purchase, in accordance with any information we have provided to you before you place your order, and that match any samples that you have seen or examined (unless we have made you aware of any differences).</p>

                        <p>Your remedies where goods are faulty, damaged or incorrect</p>

                        <p>14.2 If any products you have purchased do not comply and, for example, have faults or are damaged when you receive them, or if you receive incorrect or incorrectly priced goods, please contact us as soon as reasonably possible to inform us of the fault, damage or error, and to arrange for one or more of the following remedies:</p>

                        <ul>
                            <li>30 day right to reject: Beginning on the day that you receive the goods you have a 30 day right to reject the goods and to receive a full refund (this is called the ‘short term right to reject’). Please note if any of the goods are of a kind that can reasonably be expected to perish after a shorter period, the time limit for exercising the short-term right to reject in relation to those goods is the end of that shorter period;</li>
                            <li>Repair or replacement: If you do not wish to reject the goods, or if the short term right to reject has expired, you may request that the goods be replaced or repaired. We will bear any associated costs and will provide the repair or replacement (as applicable) within a reasonable time and without significant inconvenience to you.</li>
                            <li>Refund: In certain circumstances, where a repair or replacement is impossible or disproportionate, we may instead offer you a full refund. In addition, if we have repaired or replaced the goods and they still do not conform then you have the right either to keep the goods at a reduced price, or reject them in exchange for a refund (this is called the ‘final right to reject’).</li>
                        </ul>

                        <p>14.3 Please note:</p>

                        <ul>
                            <li>if you are exercising your short term right to reject, it will be your responsibility to prove that the products are defective, faulty or incorrect. Similarly it will also be your responsibility to prove that the goods are defective, faulty or incorrect once 6 months have passed since you received the goods; and</li>
                            <li>if you exercise the final right to reject the goods (as described above) more than 6 months after you have received the goods, we may reduce any refund to reflect the use that you have had out of the goods; and</li>
                        </ul>

                        <p>When are you not able to claim for faulty, damaged or incorrect goods?</p>

                        <p>14.4 You will not be eligible to claim under this section if:</p>

                        <ul>
                            <li>we informed you of the fault(s), damage or other problems with the goods before you purchased them (and it is because of the same issue that you now wish to return them);</li>
                            <li>you have purchased the goods for an unsuitable purpose that is neither obvious nor made known to us and the problem has resulted from your use of the goods for that purpose; or</li>
                            <li>if the problem is the result of normal wear and tear, misuse or intentional or careless damage.</li>
                        </ul>

                        <p>14.5 Please also note that rights set out in this section 14 do not allow you to return the goods because you have changed your mind. Please instead refer to section 15 (Cancelling and returning goods if you change your mind) which sets out information about your rights if you change your mind.</p>

                        <p>Your remedies where there is a problem with services received by you</p>

                        <p>14.6 If there is a problem with a product which is a service, please contact us as soon as reasonably possible to inform us of the fault, damage or error, and to arrange for one or more of the following remedies:</p>

                        <ul>
                            <li>you can ask us to repeat or fix a service if it's not carried out with reasonable care and skill, or get some money back if we can't fix it.</li>
                            <li>if you haven't agreed a price beforehand, what you're asked to pay must be reasonable.</li>
                            <li>if you haven't agreed a time beforehand, it must be carried out within a reasonable time.</li>
                        </ul>

                        <p>How do I return faulty or incorrect goods to you</p>

                        <p>14.7 Please see our Returns Policy for details as to how to return goods to us under this section 14. We will be fully responsible for the costs of returning goods under this section 14 and will reimburse you where appropriate.</p>

                        <p>Refunds</p>

                        <p>14.8 Refunds under this section 14 will be issued within 14 days of the day on which we agree that you are entitled to the refund.</p>

                        <p>14.9 Any and all refunds issued under this section 14 will include all delivery costs paid by you when the goods were originally purchased and will be made using the same payment method that you used when ordering the goods, unless you specifically request that we use a different method.</p>


                        <p>15. OUR RESPONSIBILITY FOR LOSS AND DAMAGE SUFFERED BY YOU</p>

                        <p>15.1 We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes death or personal injury caused by our negligence (including that of our employees or sub-contractors) or for fraud or fraudulent misrepresentation.</p>

                        <p>We are not responsible for loss which is not foreseeable</p>

                        <p>15.2 If we fail to comply with these Terms of Sale, we shall only be liable to you for losses that you suffer as a result of our failure to comply (whether arising in contract, tort (including negligence), breach of statutory duty or otherwise) which are a foreseeable consequence of such failure. Loss or damage is foreseeable if it is an obvious consequence of our breach or negligence or if it is contemplated by you and us when the contract is created.</p>

                        <p>15.3 We will take all reasonable care, in so far as it is in our power to do so, to keep the details of your order and payment secure, but in the absence of negligence on our part we cannot be liable for any loss you may suffer if a third party procures unauthorised access to any data provided by you when accessing or ordering from the Website.</p>

                        <p>We only supply goods for domestic and private use</p>

                        <p>15.4 We only supply the goods for domestic and private use. If you use the goods for any commercial, business or re-sale purpose we will have no liability to you for any loss of profit, loss of business, interruption to business or for any loss of business opportunity whatsoever.</p>

                        <p>Your rights as a consumer</p>

                        <p>15.5 Please note that nothing in these Terms of Sale seeks to exclude or limit any of your rights as a consumer. More information can be obtained from your local Citizens’ Advice Bureau or Trading Standards Office.</p>

                        <p>16. EVENTS OUTSIDE OF OUR CONTROL</p>

                        <p>16.1 We are not responsible for any failure or delay in performing our obligations where that failure or delay results from any event that is outside of our control. Such events include, but are not limited to: power failure, internet service provider failure, industrial action, civil unrest, fire, flood, storms, earthquakes, subsidence, acts of terrorism or war, governmental action, pandemic, other natural disaster, or any other event that is beyond our control.</p>

                        <p>16.2 If we are delayed or fail to perform our obligations as a result of an event outside of our control then we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay. If there is a risk of substantial delay then you may contact us to end the contract and receive a refund for any goods you have paid for but have not received.</p>

                        <p>17. WE MAY TRANSFER THESE TERMS TO SOMEONE ELSE</p>

                        <p>We may transfer our rights and obligations under these Terms of Sale to another organisation. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under these Terms of Sale.</p>

                        <p>18. YOU MAY ONLY TRANSFER THESE TERMS AND CONDITIONS IF WE AGREE</p>

                        <p>You may only transfer your obligations and rights under these Terms of Sale if we agree in writing.</p>

                        <p>19. NOBODY ELSE HAS RIGHTS UNDER THESE TERMS</p>

                        <p>Only you and us are entitled to enforce these Terms of Sale. Nothing in these Terms of Sale shall give, directly or indirectly, any third party any enforceable benefit or any right of action against us and such third parties shall not be entitled to enforce any term of these Terms of Sale against us.</p>

                        <p>20. INVALID PARTS OF THESE TERMS</p>

                        <p>If any part of the Terms of Sale shall be deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms of Sale and shall not affect the validity and enforceability of any remaining provisions of the Terms of Sale.</p>

                        <p>21. EVEN IF WE DELAY IN ENFORCING THESE TERMS, WE CAN STILL ENFORCE THEM LATER</p>

                        <p>Any delay or failure by us in exercising, or any waiver by us of, our rights under or in connection with these Terms of Sale will not limit or restrict the future exercise or enforceability of those rights.</p>

                        <p>22. WHICH COUNTRY’S LAWS APPLY TO ANY DISPUTES AND WHERE YOU CAN BRING PROCEEDINGS?</p>

                        <p>These Terms of Sale shall be governed by and construed in accordance with the laws of England and Wales and any disputes will be decided only by the English courts, except that if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may also bring proceedings in Scotland.</p>

                        <p>13. SOCIAL MEDIA CONTENT USE TERMS AND CONDITIONS</p>

                        <p>1. By permitting Retrodayz (registered in England and Wales under company registration number (VAT Number required)) whose registered office is at (Address Required) or any other member of Retrodayz (“we”, “our” or “us”) to use particular Content (by affirmatively answering a request from us or on our behalf on social media or otherwise indicating your agreement for to use your Content), you agree to be bound by these Social Media Content Use Terms and Conditions (“Terms”).</p>

                        <p>2. In these Terms “Content” refers to the photo and/or video, including any sound and accompanying text, posted through your account on social media, accompanying metadata such as time and place of creation, your user name, and links to your social media profile.</p>

                        <p>3. You grant us a right to use and display the Content, in any media, worldwide, for any lawful commercial purpose, in original or edited form, including without limitation: on our social media accounts, websites, blogs, digital displays, advertising, and generally in the promotion of our products or services. Such rights also extend to our group companies, affiliates, agents, employees, representatives, assigns, licensees, and anyone else acting on behalf of us.</p>

                        <p>4. The rights that you grant to us to use your Content are perpetual and irrevocable and you may not cancel or withdraw your permission for us to use your Content. However, this does not affect your or any other person’s rights in relation to personal data as referred to below.</p>

                        <p>5. The rights you grant to us are non-exclusive and (subject to our rights) you retain all rights to use the Content for your own purposes. We will not pay any royalties or compensation to you or any third party for use of the Content. Use is at our discretion and we are not obliged to use your Content in any particular way or at all.</p>

                        <p>6. You agree that you do not have any right to review, approve, or object to our use of your Content or advertising copy, or to be identified as the author/creator of the Content.</p>

                        <p>7. By agreeing to these Terms you represent and warrant to us that:</p>

                        <p>(a) the Content is your original work and you own it and all intellectual property rights in it and have all necessary rights to grant us the rights set out in these Terms (including in relation to copyrights, trademarks, private or public buildings, performances, sounds, and music) or, if you are not the owner of such rights, you have already obtained permission from the owner of such rights, and will inform us if this is incorrect or such permission is withdrawn at any time;</p>

                        <p>(b) our use of your Content as provided herein will not infringe intellectual property, privacy, image, performance, or publicity rights, or any other rights of any third party, require the payment of any compensation to any third party, or breach any applicable laws;</p>

                        <p>(c) nothing in your Content will be illegal, defamatory, obscene, or threatening to any third party; and</p>

                        <p>(d) you are aged 18 or over and have the right to agree to these Terms.</p>

                        <p>8. You will indemnify and keep us indemnified in full from and against any and all liability, loss, damages, injury, costs and expenses (including increased administration costs and reasonable legal expenses) awarded against, or incurred or paid by us as a result of or in connection with the Content, including without limitation for breach of intellectual property, data protection, privacy, image, performance, or publicity rights.</p>

                        <p>9. The Content may contain information about you or identifiable individuals which constitutes personal data under applicable laws.</p>

                        <p>10. By agreeing to these Terms you give your consent to the processing of such personal data by us (including any third party service providers that we engage for such purposes, as referred to below) for the purposes set out in these Terms.</p>

                        <p>11. Where any personal data relating to any other person appears in the Content you warrant that you also have obtained their consentfor the processing of such personal data.</p>

                        <p>12. We will process personal data included in the Content or otherwise provided to us in connection with our use of the Content in accordance with all applicable data protection laws and our website privacy policy.</p>

                        <p>13. We are the controller of your personal data and you can obtain further information or exercise any of your legal rights by contacting us on the details below:</p>

                        <p>Email: support@retrodayz.co.uk</p>

                        <p>Address: Address Required</p>

                        <p>14. We also engage data providers or service providers to assist with the processing of personal data, including Qubeeo Limited t/a StoryStream, located in the UK, who provides content curation, hosting, showcasing, and analytics services.</p>

                        <p>15. RETURNS POLICY</p>

                        <p>Our Returns Policy forms part of, and must be read in conjunction with, our Terms and Conditions of Sale which applies. We reserve the right to change this Returns Policy at any time.</p>

                        <p>1. CHRISTMAS RETURNS POLICY - CLOTHING & ACCESSORIES</p>

                        <p>Made a mistake? We've got you covered…</p>

                        <p>1.1 [We've extended our usual returns policy for the Christmas period, so you have time to change your mind. Any clothing or accessories purchases made between 01/11/20 and 24/12/20 can be returned up until 31/01/21 if it's unwanted or unsuitable. This will not affect your statutory rights, and our Terms and Conditions of Sale apply.]</p>

                        <p>1.2 Please note that due to hygiene reasons, underwear and facemasks are non-returnable.</p>

                        <p>2. RETURNS POLICY - CLOTHING</p>

                        <p>2.1 We offer free UK returns on all clothing items purchased on Retrodayz.co.uk. Please ensure that when you receive an item that you take reasonable care of it when trying it on and please ensure that any security seals or tags are still intact.</p>

                        <p>2.2 Please note that due to hygiene reasons, underwear and facemasks are non-returnable.</p>

                        <p>How long have I got to return an item of clothing?</p>

                        <p>2.3 You have 30 days from the date of receiving your order to initiate a return.</p>

                        <p>How do I return clothing?</p>

                        <p>2.4 It's so simple, please follow the steps set out below (please see the section headed 'What to do to return your item to us') or alternatively complete the attached cancellation form and email it to: Retrodayz.</p>

                        <p>2.5 If you have any queries or need any help then please contact us Retrodayz.</p>

                        <p>3. RETURNS POLICY - ALL OTHER PRODUCTS</p>

                        <p>Our Returns Policy is really simple!</p>

                        <p>3.1 When you receive your item, you must check it as soon as possible following receipt and always before use.</p>

                        <p>3.2 Please ensure that a returns authorisation number is obtained before attempting to return any items to us.</p>

                        <p>Your right to change your mind.</p>

                        <p>3.3 We've all done it, ordered something and then realised later that it is no longer needed. You have 14 days to return your order, which starts on the day after you received the item.</p>

                        <p>How do I return my product?</p>

                        <p>3.4 It's so simple, please follow the steps set out below (please see the section headed 'What to do to return your item to us').</p>

                        <p>Do you need me to do anything else?</p>

                        <p>3.5 All items must be returned unused and in the original packaging as they were in when received by you. Please ensure that when you receive an item that you take reasonable care of it when trying it on or inspecting it and that any security seals or tags are still intact.</p>

                        <p>4. WRONG ITEM RECEIVED</p>

                        <p>4.1 We apologise if you have received the wrong item by mistake. To receive a refund or a replacement, you must return the item in the same condition you received it.</p>

                        <p>4.2 This is not common and we want to resolve this as quickly as possible for you so please let us know within 14 days from the day on which you received the item. If you notify us after this then depending on the circumstances we may not be able to refund or offer a replacement.</p>

                        <p>5. DAMAGED, FAULTY, INCORRECT OR MISSING ITEMS</p>

                        <p>5.1 Please accept our apologies if you have received an item that is damaged or faulty. We understand how frustrating this can be and want to resolve this as quickly as possible for you.</p>

                        <p>5.2 If you believe that the item is faulty, please contact us. You will need to tell us exactly what the fault is and as soon as possible after discovering the fault and we will advise what to do next.</p>

                        <p>5.3 For more information about your rights and the remedies available to you please see section 17 of our General Terms and Conditions of Sale.</p>

                        <p>6. ITEM NOT RECEIVED</p>

                        <p>We apologise if you have not received your item. This is not common and we want to resolve this as quickly as possible for you so please let us know within 21 days from the day on which you received an email from us confirming that the item had been dispatched. If you notify us after this then depending on the circumstances we may not be able to offer a refund or replacement.</p>

                        <p>7. YOUR LEGAL RIGHTS</p>

                        <p>7.1 Our Returns Policy does not affect your legal rights.</p>

                        <p>7.2 For more information about your other legal rights, please visit the UK Government's website at: www.direct.gov.uk or contact Consumer Direct, the Government funded consumer advice service on 08454 04 05 06.</p>

                        <p>8. WHAT TO DO TO RETURN YOUR ITEM TO US</p>

                        <p>8.1 To return your item, you will need to register your return by logging on to your account here, selecting your order and clicking the ‘Return’ button where you will be guided through the process. Alternatively you can use our model cancellation form.</p>

                        <p>8.2 Once you have processed your return online you have a further 14 days to return your item to us. Please make sure your items are securely wrapped and your parcel contains all the information requested.</p>

                        <p>8.3 Remember to ask for a receipt as proof of sending. Proof of postage does not cost anything however without it, we may not be able to process your refund or replacement in the rare event that your item is lost in transit.</p>

                        <p>8.4 If you have any queries or need any help then please contact us through your account via our online message centre or call us on [0044 (0) 161 8131487].</p>

                        <p>9. WHAT HAPPENS IF I REQUEST A REPLACEMENT AND IT ISN’T AVAILABLE?</p>

                        <p>9.1 If you request a replacement and the product is no longer available, we will process the refund back to the original credit/debit card/PayPal account used to purchase the item.</p>

                        <p>9.2 Any item you have accepted and then return is your responsibility until it reaches our warehouse. Please therefore ensure that you send your item back to us using a delivery service that insures you for the value of the goods.</p>

                        <p>10. WHAT WE'LL DO NEXT</p>

                        <p>10.1 Once we have processed your return you will receive a notification via email.</p>

                        <p>10.2 All successful returns will be credited to the original method used for payment. Please note refunds can take 3 to 5 working days (Monday to Friday, excluding bank holidays) to show in your account.</p>

                        <p>11. COMPLAINTS</p>

                        <p>11.1 If you are not satisfied with the way in which we have handled the return, replacement or repair of any item, we apologise. We want to resolve the matter.</p>

                        <p>11.2 Please contact our customer service representatives via your account or by post at [Customer Service Department, Retrodayz, Address required].</p>

                        <p>(Complete and return this form only if you wish to withdraw from the contract)</p>

                        <p>To: RetrodayzGroup Limited (trading as Retrodayz)</p>

                        <p>Address: (Address Required)</p>

                        <p>Telephone: Retrodayz</p>

                        <p>Email: Retrodayz</p>

                        <p>I/We [*] hereby give notice that I/We [*] cancel my/our [*] contract of sale of the following goods [*]/for the supply of the following service [*],Ordered on [*]/received on [*],</p>

                        <p>Name of consumer(s):</p>

                        <p>Address of consumer(s):</p>

                        <p>Signature of consumer(s) (only if this form is notified on paper):</p>

                        <p>Date:</p>

                    </div>
                </section>
            </div>
        </div>
    </>
        );
    };

export default Terms;