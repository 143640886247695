import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider } from "react-router-dom";
import { PayPalScriptProvider} from "@paypal/react-paypal-js";
import { Provider } from    'react-redux';
import store from "./store";
import "./assets/styles/bootstrap.custom.css";
import "./assets/styles/styles.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import About from "./screens/About";
import Help from "./screens/Help";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
import LoginScreen from "./screens/LoginScreen"
import RegisterScreen from "./screens/RegisterScreen";
import ShippingScreen from "./screens/ShippingScreen";
import PrivateRoute from "./components/PrivateRoute";
import AdminRoute from "./components/AdminRoute";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import ProfileScreen from "./screens/ProfileScreen";
import ProfileOrderScreen from "./screens/ProfileOrderScreen";
import OrderListScreen from "./screens/admin/OrderListScreen";
import ProductListScreen from "./screens/admin/ProductListScreen";
import ProductEditScreen from "./screens/admin/ProductEditScreen";
import UserListScreen from "./screens/admin/UserListScreen";
import UserEditScreen from "./screens/admin/UserEditScreen";
import {HelmetProvider} from "react-helmet-async";
import {CgToggleOn} from "react-icons/cg";
import Contact from "./screens/Contact";
import PasswordResetScreen from "./screens/PasswordReset";
import UserPasswordReset from "./screens/UserPasswordReset";
import Terms from "./screens/Terms";
import Cookie from "./screens/Cookie";
import Faq from "./screens/Faq";
import Accessibility from "./screens/Accessibility";
import Privacy from "./screens/Privacy";
import VerifyEmailScreen from "./screens/VerifyEmailScreen";
import ResendVerificationScreen from "./screens/ResendVerificationScreen";
import LandingPage from "./screens/LandingPage";


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />}>
           <Route index={true} element={<LandingPage />} /> {/* This is the new landing page, remove for now. */}
            <Route path="/" element={<HomeScreen />} />
            <Route path="/search/:keyword?" element={<HomeScreen />} />
            <Route path="/search/:keywordDescription" element={<HomeScreen />} />
            <Route path="/page/:pageNumber" element={<HomeScreen />} />
            <Route path="/search/:keyword/page/:pageNumber" element={<HomeScreen />} />
            <Route path='/product/:id' element={<ProductScreen />} />
            <Route path='/cart' element={<CartScreen />} />
            <Route path='/login' element={<LoginScreen />} />
            <Route path='/register' element={<RegisterScreen />} />
            <Route path='/about' element={<About />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/help' element={<Help />} />
            <Route path='/cookie' element={<Cookie />} />
            <Route path='/faqs' element={<Faq />} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/accessibility' element={<Accessibility />} />
            <Route path='/passwordreset' element={<PasswordResetScreen />} />
            <Route path='/reset/:token' element={<UserPasswordReset />} />
            <Route path='/verify-email' element={<VerifyEmailScreen />} />
            <Route path='/resend-verification' element={<ResendVerificationScreen />} />

            <Route path='' element={<PrivateRoute />}>
                <Route path='/shipping' element={<ShippingScreen />} />
                <Route path='/payment' element={<PaymentScreen />} />
                <Route path='/placeorder' element={<PlaceOrderScreen />} />
                <Route path='/order/:id' element={<OrderScreen />} />
                <Route path='/profile' element={<ProfileScreen />} />
                <Route path='/orders' element={<ProfileOrderScreen />} />
            </Route>

            <Route path='' element={<AdminRoute />}>
                <Route path='/admin/orderlist' element={<OrderListScreen />} />
                <Route path='/admin/productlist' element={<ProductListScreen />} />
                <Route path='/admin/productlist/:pageNumber' element={<ProductListScreen />} />
                <Route path='/admin/product/:id/edit' element={<ProductEditScreen />} />
                <Route path='/admin/userlist' element={<UserListScreen />} />
                <Route path='/admin/user/:id/edit' element={<UserEditScreen />} />
            </Route>
        </Route>
    )
);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <HelmetProvider>
        <Provider store={store}>
        <PayPalScriptProvider deferLoading={ true }>
            <RouterProvider router={router} />
        </PayPalScriptProvider>
        </Provider>
      </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
