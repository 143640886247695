import React, { useState, useEffect } from 'react';
import { useFavoritesMutation, useRemoveFavoritesMutation, useFavoriteStatusQuery } from '../slices/usersApiSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons'; // Filled heart icon
import { faHeart as faHeartOutline } from '@awesome.me/kit-367a27fd96/icons/classic/thin'; // Outlined heart icon

const Favorite = ({ productId, initialIsFavorite }) => {
    const [isFavorite, setIsFavorite] = useState(initialIsFavorite);
    const [addToFavorites] = useFavoritesMutation('favorites'); // Mutation hook for adding to favorites
    const [removeFromFavorites] = useRemoveFavoritesMutation('removeFavorites'); // Mutation hook for removing from favorites
    const { data: favoriteStatus, isLoading, isError } = useFavoriteStatusQuery(productId); // Query hook for checking favorite status

    useEffect(() => {
        if (favoriteStatus && favoriteStatus.message === 'Product is in favorites') {
            setIsFavorite(true);
        } else {
            setIsFavorite(false);
        }
    }, [favoriteStatus]);

    // Log the behavior of useFavoriteStatusQuery
    console.log("Favorite status query data:", favoriteStatus);
    console.log("Is loading:", isLoading);
    console.log("Is error:", isError);

    const toggleFavorite = async () => {
        try {
            if (isFavorite) {
                // If already favorited, remove it from favorites
                await removeFromFavorites({ productId });
            } else {
                // If not favorited, add it to favorites
                await addToFavorites({ productId });
            }
            // Toggle the favorite state
            setIsFavorite(!isFavorite);
        } catch (error) {
            console.error('Error toggling favorite:', error);
        }
    };

    return (
        <div>
            <button className="favorite-button" onClick={toggleFavorite} style={{ display: 'flex', alignItems: 'center', width: '180px' }}>
                <FontAwesomeIcon
                    icon={isFavorite ? faHeart : faHeartOutline}
                    className="fa-beat"
                    //heartbeat speed, 0.5 beats faster, 5 goes slower
                    style={{
                        '--fa-animation-duration': '5s',
                        color: isFavorite ? '#FD7014' : 'black',
                        fontSize: '26px',
                        padding: '10px 10px',
                    }}
                />
                <div style={{ color: 'black', marginRight: '6px' }}>
                    {isFavorite ? 'Saved To Wishlist' : 'Save To Wishlist'}
                </div>
            </button>
        </div>

    );
};

export default Favorite;
