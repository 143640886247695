import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Rating from "./Rating";

const Product = ({ product, showDiscount }) => {
    // Read the discount value from the product object
    const discount = product.discount ?? 0; // Default to 0 if no discount is provided
    const discountedPrice = (product.price - (product.price * (discount / 100))).toFixed(2);
    const discountPercentage = (discount).toFixed(0); // Format discount percentage to 0 decimal places

    // Log the discount percentage to the console
    console.log('Discount Percentage:', discountPercentage);

    return (
        <Card className='my-3 p-3 rounded'>
            <Link to={`/product/${product._id}`}>
                <Card.Img src={product.image} variant='top' alt={product.name} />
            </Link>

            <Card.Body>
                <Link to={`/product/${product._id}`} style={{ textDecoration: 'none' }}>
                    <Card.Title as="div" className="product-title">
                        <strong>{product.name}</strong>
                    </Card.Title>
                </Link>

                {showDiscount && product.isClearance && discount > 0 ? (
                    <>
                        <Card.Text as='div' className="product-price original-price" style={{ textDecoration: 'line-through' }}>
                            £{product.price.toFixed(2)}
                        </Card.Text>
                        <Card.Text as='div' className="product-price discounted-price">
                            Now: £{discountedPrice}
                        </Card.Text>
                        <Card.Text as='div' className="product-price product-promo">
                            Save {discountPercentage}%
                        </Card.Text>
                    </>
                ) : (
                    <Card.Text as='div' className="product-price">
                        £{product.price.toFixed(2)}
                    </Card.Text>
                )}

                <Card.Text as="div" className="product-rating">
                    <Rating value={product.rating} text={`${product.numReviews} reviews`} />
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default Product;
