import {Badge, Navbar, Nav, Container, NavDropdown} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {FaBookOpen, FaMobileAlt, FaShoppingBasket, FaUser} from "react-icons/fa";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import logo from "../assets/logo.png";
import {LinkContainer} from 'react-router-bootstrap';
import {useSelector, useDispatch} from "react-redux";
import {useLogoutMutation} from "../slices/usersApiSlice";
import {logout} from "../slices/authSlice";
import {useEffect, useState} from "react";
import SearchBox from "./SearchBox";
import {resetCart} from "../slices/cartSlice";


const Header = () => {
    const {cartItems} = useSelector((state) => state.cart);
    const {userInfo} = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isSticky, setIsSticky] = useState(false);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [logoutApiCall] = useLogoutMutation();

    useEffect(() => {
        const threshold = document.getElementById('nav-dropdownThree').offsetHeight; // This gets the height of the element above your Navbar.

        const handleScroll = () => {
            if (window.scrollY > threshold) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const header = document.querySelector('.sticky');
        if (header) {
            setHeaderHeight(header.offsetHeight);
        }
    }, [isSticky]);

    const logoutHandler = async () => {
        console.log("Logout handler called");
        try {
            await logoutApiCall().unwrap();
            dispatch(logout());
            dispatch(resetCart());
            navigate('/login');
        } catch (err) {
            console.log(err);
        }
    };

    const handleMouseEnter = () => {
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    return (
        <header>
            <div style={{
                borderTop: '2px solid #3E6D9C',
                backgroundColor: '#3E6D9C',
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '20px'
            }} id='nav-dropdownThree'>
                <LinkContainer to="/Contact" style={{backgroundColor: '#3E6D9C'}}>
                    <Navbar.Brand style={{fontSize: '0.4rem'}}>
                        <FaMobileAlt/> Contact us
                    </Navbar.Brand>
                </LinkContainer>
                <LinkContainer to="/Help" style={{marginLeft: '20px'}}>
                    <Navbar.Brand>
                        <FaBookOpen/> Help
                    </Navbar.Brand>
                </LinkContainer>
                <Navbar style={{backgroundColor: "#FFF", borderTop: '10px solid #3E6D9C'}} variant="light"/>
            </div>
            <div className="header-wrapper" style={{height: isSticky ? `${headerHeight}px` : 'auto'}}>
                <div className={isSticky ? 'sticky' : ''}>
                    <Navbar expand="xl" collapseOnSelect style={{backgroundColor: 'white'}} >
                        <Container>
                            <LinkContainer to="/" id='nav-dropdown'>
                                <Navbar.Brand>
                                    <img src={logo} alt="RetroDayz" style={{ opacity: 1.0 }}/>
                                </Navbar.Brand>
                            </LinkContainer>
                            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="ms-auto">
                                    <div className="parent-container">
                                        <SearchBox  />
                                    </div>
                                    {userInfo ? (
                                        <NavDropdown
                                            title={userInfo.name}
                                            id='nav-user-dropdown'
                                            className="nav-dropdown"
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            <LinkContainer to='/orders'>
                                                <NavDropdown.Item>Order History </NavDropdown.Item>
                                            </LinkContainer>
                                            <LinkContainer to='/profile'>
                                                <NavDropdown.Item>User Profile </NavDropdown.Item>
                                            </LinkContainer>
                                            <NavDropdown.Item onClick={logoutHandler}>
                                                Logout
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                    ) : (
                                        <LinkContainer to='/login' id='nav-login'>
                                            <Nav.Link className="signin-link">
                                                <FaUser className="signin-icon" /> Account
                                            </Nav.Link>
                                        </LinkContainer>
                                    )}
                                    {userInfo && userInfo.isAdmin && (
                                        <NavDropdown title='Admin' id='nav-dropdown' className="nav-dropdown" >
                                            <LinkContainer to='/admin/orderlist'>
                                                <NavDropdown.Item>Orders</NavDropdown.Item>
                                            </LinkContainer>
                                            <LinkContainer to='/admin/productlist'>
                                                <NavDropdown.Item>Products</NavDropdown.Item>
                                            </LinkContainer>
                                            <LinkContainer to='/admin/userlist'>
                                                <NavDropdown.Item>Users</NavDropdown.Item>
                                            </LinkContainer>
                                        </NavDropdown>
                                        )}
                                    <LinkContainer to="/cart">
                                        <Nav.Link className="basket-link" >
                                            {/*basket colour below*/}
                                            <div style={{color: "#000000"}}><FaShoppingBasket className="basket-icon" /> Basket
                                                {
                                                    cartItems.length > 0 && (
                                                        <Badge pill bg='success' style={{marginLeft: '5px'}}>
                                                            {/* 'a' is accumulator, and 'c' is the current value - sc*/}
                                                            { cartItems.reduce((a,c) => a + c.qty, 0) }
                                                        </Badge>
                                                    )
                                                }
                                            </div>
                                        </Nav.Link>
                                    </LinkContainer>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>
            </div>
        </header>
    );
};

export default Header;